import { apiSlice } from "../api/apiSlice";

export const userAdminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // ADMIN USER
    getAllUserAdmin: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/users-admin`,
          params: params,
        };
      },
      providesTags: ["GetAllUserAdmin"],
    }),
    getUserAdminById: builder.mutation<any, any>({
      query: ({ user_admin_uid }) => {
        return {
          url: `/user-admin/${user_admin_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetUserAdminById"],
    }),
    createUserAdmin: builder.mutation<any, any>({
      query: (body) => ({
        url: "/user-admin",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreateUserAdmin", "GetAllUserAdmin"],
    }),
    updateUserAdmin: builder.mutation<any, any>({
      query: ({body, user_admin_uid}) => ({
        url: `/user-admin/${user_admin_uid}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateUserAdmin', 'GetAllUserAdmin']
    }),
    updateUserAdminPassword: builder.mutation<any, any>({
      query: ({body, user_admin_uid}) => ({
        url: `/user-admin-password/${user_admin_uid}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateUserAdminPassword', 'GetAllUserAdmin']
    }),
    deleteUserAdmin: builder.mutation<any, any>({
      query: ({ user_admin_uid }) => ({
        url: `/user-admin/${user_admin_uid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteUserAdmin", "GetAllUserAdmin"],
    }),

    // ADMIN ROLES
    getAllRoles: builder.query<any, any>({
      query: () => {
        return {
          url: `/roles`,
        };
      },
      providesTags: ["GetAllRoles"],
    }),

  }),
});

export const {
  useGetAllUserAdminQuery,
  useGetUserAdminByIdMutation,
  useCreateUserAdminMutation,
  useUpdateUserAdminMutation,
  useUpdateUserAdminPasswordMutation,
  useDeleteUserAdminMutation,
  useGetAllRolesQuery
} = userAdminApiSlice;

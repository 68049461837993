import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import ListIcon from '@mui/icons-material/List';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/',
    icon: DashboardTwoToneIcon,
    child: [],
  },
  {
    key: 'uno-product',
    label: 'Uno Product',
    path: 'uno-product',
    icon: DevicesTwoToneIcon,
    child: [
      {
        key: 'product-list',
        label: 'Product List',
        path: 'product-list',
        icon: FormatListBulletedIcon,
        child: [],
      },
      {
        key: 'pricing-config',
        label: 'Pricing Config',
        path: 'pricing-config',
        icon: LocalOfferIcon,
        child: [],
      },
      {
        key: 'product-subscriptions',
        label: 'Product Subscriptions',
        path: 'product-subscriptions',
        icon: SubscriptionsIcon,
        child: [],
      },
    ],
  },
  {
    key: 'company',
    label: 'Company',
    path: 'company',
    icon: BusinessIcon,
    child: [
      {
        key: 'company-list',
        label: 'Company List',
        path: 'company-list',
        icon: ListIcon,
        child: [],
      },
    ],
  },
  {
    key: 'payment',
    label: 'Payment',
    path: 'payment',
    icon: PaymentIcon,
    child: [
      {
        key: 'billing-invoice',
        label: 'Billing Invoice',
        path: 'billing-invoice',
        icon: PaymentsIcon,
        child: [],
      },
    ],
  },
  {
    key: 'user-admin-management',
    label: 'User Admin Management',
    path: 'user-admin-management',
    icon: PeopleAltIcon,
    child: [
      {
        key: 'user-admin',
        label: 'User Admin',
        path: 'user-admin',
        icon: PersonIcon,
        child: [],
      },
    ],
  },
];

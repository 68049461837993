import React from 'react';
import {
  Box,
  FormControl,
  SxProps,
  Theme,
  Typography,
  useTheme,
  Skeleton,
  IconButton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import FormLabel from '../../../atoms/formLabel/index';
import { StyledBox, StyledInput } from './elements';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  maxLength?: number;
  disabled?: boolean;
  type?: string;
  onChange?: Function;
  required?: boolean;
  placeholder?: string;
  isUpperCase?: boolean;
  specificName?: string;
  isLoading?: boolean;
  showStartAdornment?: boolean;
  showEndAdornment?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  sxEndAdornment?: SxProps<Theme>;
  onClickEndAdornment?: Function;
  showPrice?: boolean;
}

export default function InputForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    errors,
    maxLength = 100,
    disabled,
    type = 'text',
    onChange,
    required = false,
    placeholder = '',
    isUpperCase = false,
    specificName = '',
    isLoading,
    showStartAdornment,
    showPrice,
    showEndAdornment,
    startAdornment = <Typography variant="body16Regular">%</Typography>,
    endAdornment = <Typography variant="body16Regular">%</Typography>,
    sxEndAdornment,
    onClickEndAdornment = () => {},
  } = props;
  const { palette } = useTheme();

  const handleIncrement = (field: any) => {
    const newValue = parseFloat(field.value || 0) + 1;
    field.onChange(newValue.toString());
    onChange?.(newValue);
  };

  const handleDecrement = (field: any) => {
    const newValue = parseFloat(field.value || 0) - 1;
    field.onChange(newValue.toString());
    onChange?.(newValue);
  };

  return (
    <FormControl
      sx={{ flex: 1, display: 'flex', mb: '1.5rem', ...sxFC }}
      variant="standard"
    >
      {label?.length !== 0 && (
        <FormLabel htmlFor={name} sx={{ color: palette.neutral.dark, ...sxFL }}>
          {label}
          {!required && !disabled && (
            <span
              style={{
                marginLeft: '4px',
                color: palette.neutral.light,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontStyle: 'italic',
              }}
            >
              (optional)
            </span>
          )}
          {required && label !== undefined && !disabled && (
            <span style={{ color: '#ef4545' }}> *</span>
          )}
        </FormLabel>
      )}
      {!isLoading && (
        <Controller
          defaultValue=""
          control={control}
          name={name}
          render={({ field }) => (
            <StyledInput
              {...field}
              onWheel={(e: any) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              sx={{
                '& input': {
                  height: '40px', // Fixed height
                  padding: '8px', // Adjust padding to match height
                  boxSizing: 'border-box', // Ensure padding is included in the height
                  textTransform: isUpperCase ? 'uppercase' : 'none',
                },
              }}
              disabled={disabled}
              size="small"
              id={name}
              inputProps={{
                maxLength,
                placeholder,
              }}
              autoComplete={
                type === 'password' || type === 'email' ? 'new-password' : 'off'
              }
              placeholder={placeholder}
              error={!!errors[name] || !!errors[specificName]}
              helperText={errors[name]?.message || errors[specificName]?.message}
              type={type}
              InputProps={{
                endAdornment: showPrice ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center', // Center buttons vertically
                      height: '100%',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleIncrement(field)}
                      disabled={disabled}
                      sx={{ height: '20px', padding: 0 }} // Adjust height to half of the input
                    >
                      <ArrowDropUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDecrement(field)}
                      disabled={disabled}
                      sx={{ height: '20px', padding: 0 }} // Adjust height to half of the input
                    >
                      <ArrowDropDownIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : showEndAdornment ? (
                  <Box
                    onClick={() => {
                      onClickEndAdornment();
                    }}
                    sx={{
                      background: palette.gray.light,
                      height: '40px',
                      width: '33px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${palette.gray.main}`,
                      borderTopRightRadius: '4px',
                      borderBottomRightRadius: '4px',
                      ...sxEndAdornment,
                    }}
                  >
                    {endAdornment}
                  </Box>
                ) : null,
                startAdornment: showStartAdornment && (
                  <Box
                    sx={{
                      background: palette.gray.light,
                      height: '40px', // Fixed height
                      width: '33px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${palette.gray.main}`,
                      borderTopRightRadius: '4px',
                      borderBottomRightRadius: '4px',
                      ...sxEndAdornment,
                    }}
                  >
                    {startAdornment}
                  </Box>
                ),
              }}
              onChange={(e: any) => {
                if (type === 'file') {
                  field.onChange(e.target.value);
                  onChange?.(e.target.files);
                } else {
                  if (e.target.value.length <= maxLength) {
                    field.onChange(e.target.value);
                    onChange?.(e.target.value);
                  }
                }
              }}
            />
          )}
        />
      )}
      {isLoading && (
        <StyledBox>
          <Skeleton
            style={{
              height: '40px', // Match the skeleton height to input height
              borderRadius: '4px',
            }}
          />
        </StyledBox>
      )}
    </FormControl>
  );
}

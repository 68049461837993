import { createSlice } from "@reduxjs/toolkit";
import { companyListApiSlice } from "../service/companyListApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
  companyUid: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false,
  companyUid: ''
};

const companyListSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {
      setCompanyUid: (state, action)=> {
        console.log('data from cpn ===> ', action)
        state.companyUid = action.payload
      },
      resetCpUid: (state, action) => {
        state.companyUid = ''
      }
    },
    extraReducers(builder) {
      builder
      .addMatcher(
        companyListApiSlice.endpoints.createCompanyList.matchFulfilled,
        (state, action) => {
          state.companyUid = action?.payload?.data?.company_uid
        }
      )
    }
})

// Export the actions
export const { setCompanyUid, resetCpUid } = companyListSlice.actions;

// Export the reducer
export const companyListReducer = companyListSlice.reducer;
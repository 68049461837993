import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./feature/api/apiSlice";
import { userReducer } from "./feature/reducer/userReducer";
import { productListReducer } from "./feature/reducer/productListReducer";
import { userAdminReducer } from "./feature/reducer/userAdminReducer";
import { pricingConfigReducer } from "./feature/reducer/pricingConfigReducer";
import { companyListReducer } from "./feature/reducer/companyListReducer";

export const reducer = combineReducers({
  user: userReducer,
  productList: productListReducer,
  pricingConfig: pricingConfigReducer,
  userAdmin: userAdminReducer,
  companyList: companyListReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export type RootState = ReturnType<typeof reducer>;

export const createStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
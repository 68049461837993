import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Tab, Tabs, Typography, Modal, IconButton, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm, TextAreaForm } from "../../components/molecules/forms/index";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import productList from "../../utils/validation/productList";
import { FieldMode } from "../../constants/enum/FieldMode";
import React from "react";
import { Close } from "@mui/icons-material";
import FileForm from "../../components/molecules/forms/fileForm";
import Swal from "sweetalert2";
import addDashboard from "../../utils/validation/addDashboard";
import { useCreateProductListMutation, useUpdateProductListMutation, useGetAllCategoriesQuery, useGetProductByIdMutation, useGetAllDashbordLinkMutation, useGetDashbordLinkByIdMutation, useCreateDashboardLinkMutation, useUpdateDashboardLinkMutation, useDeleteDashboardLinkMutation } from "../../store/feature/service/productListApiSlice";

const ProductSubscriptionField = () => {

  //#region INIT

  const navigate = useNavigate();
  const location = useLocation();
  const idParam = useParams();
  const [uid, setUid] = React.useState<string>();
  const { pathname } = location;
  const [mode, setMode] = useState(FieldMode.create);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [isEditDashboard, setIsEditDashboard] = React.useState(false);
  const [fileContentIcon, setFileContentIcon] = useState<String>();
  const [fileContentBanner, setFileContentBanner] = useState<String>();
  const { data: categories, isLoading: isLoadingCategory } = useGetAllCategoriesQuery({});

  const [
    getProductDetail,
    {
      data: productDetail,
      isLoading: isLoadingProduct
    },
  ]: any = useGetProductByIdMutation();

  const [
    createProduct,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      error: errorCreateMsg,
    },
  ]: any = useCreateProductListMutation();

  const [
    updateProduct,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
      error: errorUpdateMsg,
    },
  ]: any = useUpdateProductListMutation();

  const [
    getAllDashboardLink,
    {
      data: dashboardLink,
      isLoading: isLoadingDashboardLink
    },
  ]: any = useGetAllDashbordLinkMutation();

  const [
    getDashboardLinkDetail,
    {
      data: dashboardLinkDetail,
      isLoading: isLoadingDashboardLinkDetail
    },
  ]: any = useGetDashbordLinkByIdMutation();
  
  const [
    createDashboardLink,
    {
      data: createDashboardLinkPayload,
      isLoading: createDashboardLinkLoading,
      isSuccess: createDashboardLinkSuccess,
      isError: createDashboardLinkError,
      error: errorCreateDashboardLinkMsg,
    },
  ]: any = useCreateDashboardLinkMutation();

  const [
    updateDashboardLink,
    {
      data: updateDashboardLinkPayload,
      isLoading: updateDashboardLinkLoading,
      isSuccess: updateDashboardLinkSuccess,
      isError: updateDashboardLinkError,
      error: errorUpdateDashboardLinkMsg,
    },
  ]: any = useUpdateDashboardLinkMutation();

  const [
    deleteDashboardLink,
    {
      data: deleteDashboardLinkPayload,
      isLoading : deleteDashboardLinkLoading,
      isSuccess: deleteDashboardLinkSuccess,
      isError: deleteDashboardLinkError,
    }
  ] = useDeleteDashboardLinkMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<{
    uid: string;
    productName: string;
    productCode: string;
    productIcon: string;
    productImageBanner: string;
    productStatus: string;
    productCategory: string;
    productDocumentationLink: string;
    productDescription: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(productList),
    defaultValues: {
      uid: "",
      productName: "",
      productCode: "",
      productIcon: "",
      productImageBanner: "",
      productStatus: "",
      productCategory: "",
      productDocumentationLink: "",
      productDescription: "",
    },
  });

  const {
    control: controlDashboard,
    reset: resetDashboard,
    handleSubmit: handleDashboard,
    formState: { errors: errorDasboard } 
  } = useForm<{
    // dashboardStatus: string;
    dashboardUid: string;
    dashboardName: string;
    dashboardUrl: string;
    number: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(addDashboard),
    defaultValues: {
      // dashboardStatus: "",
      dashboardUid: "",
      dashboardName: "",
      dashboardUrl: "",
      number: 0
    },
  });

  const tempCategories: any = categories !== undefined ? categories?.data : []

  const optSelect = tempCategories.map((item: any) => (
    {
      label: item.product_category_name,
      value: item.product_category_uid,
    }
  ))

  const optStatus = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];

  useEffect(() => {
    if(pathname.includes("create")) {
      setMode(FieldMode.create);
    } else if (pathname.includes("edit")) {
      setMode(FieldMode.edit);
    } else if (pathname.includes("detail")) {
      setMode(FieldMode.detail);
    }
  }, []);
  
  useEffect(() => {
    if(mode !== FieldMode.create) {
      if (idParam.id !== undefined) {
        getProductDetail({ id: idParam.id})
        getAllDashboardLink({ product_uid: idParam.id})
      }
    }
  }, [mode]);

  useEffect(() => {
    if(productDetail != undefined) {
      reset({
        uid: productDetail?.data?.product_uid,
        productName: productDetail?.data?.product_name,
        productCode: productDetail?.data?.product_code,
        productIcon: productDetail?.data?.product_icon,
        productImageBanner: productDetail?.data?.product_image_banner,
        productStatus: productDetail?.data?.product_status.toUpperCase(),
        productCategory: productDetail?.data?.product_category_uid,
        productDocumentationLink: productDetail?.data?.product_api_documentation_link,
        productDescription: productDetail?.data?.product_description,
      })
      setFileContentIcon(productDetail?.data?.product_icon)
      setFileContentBanner(productDetail?.data?.product_image_banner)
    }
  }, [productDetail]);

  useEffect(() => {
    if(dashboardLink != undefined) {      
      setRowData(
        dashboardLink.data !== null ? dashboardLink.data.map((item: any, index: number) => ({
            id: item.prd_dashboard_link_uid,
            number: index+1,
            status: "inactive",
            dashboardName: item.prd_dashboard_link_name,
            dashboardUrl: item.prd_dashboard_link_url,
          }) 
        ) : []
      )
    }
  }, [dashboardLink]);

  useEffect(() => {
    if(dashboardLinkDetail != undefined) {      
      if(dashboardLinkDetail.data != null) {
        resetDashboard({
          dashboardName: dashboardLinkDetail.data.prd_dashboard_link_name,
          dashboardUrl: dashboardLinkDetail.data.prd_dashboard_link_url,
          dashboardUid: dashboardLinkDetail.data.prd_dashboard_link_uid,
          // dashboardStatus: params.row.status,
        })
      }
    }
  }, [dashboardLinkDetail]);

  useEffect(() => {
    getAllDashboardLink({ product_uid: uid})
  }, [
    createDashboardLinkSuccess,
    createDashboardLinkError,
    updateDashboardLinkSuccess,
    updateDashboardLinkError,
    deleteDashboardLinkSuccess,
     deleteDashboardLinkError
  ])

  useEffect(() => setUid(idParam.id), [idParam]);

  const onSubmit = (e: any) => {
    if(mode == FieldMode.create) {
      let params = {
        product_api_documentation_link: e.productDocumentationLink,
        product_category_uid: e.productCategory,
        product_description: e.productDescription,
        product_icon: fileContentIcon?.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
        product_image_banner: fileContentBanner?.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
        product_name: e.productName,
        product_code: e.productCode,
        product_key_option: "auto",
        product_status: e.productStatus.toLowerCase()
      }
      createProduct(params);
    } else if(mode == FieldMode.edit) {
      let params = {
        product_api_documentation_link: e.productDocumentationLink,
        product_category_uid: e.productCategory,
        product_description: e.productDescription,
        product_icon: fileContentIcon?.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
        product_image_banner: fileContentBanner?.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
        product_name: e.productName,
        product_code: e.productCode,
        product_key_option: "auto",
        product_status: e.productStatus.toLowerCase()
      }
      updateProduct({ body: params, id: e.uid });
    }
  };

  useEffect(() => {
    if(createPayload != undefined) {
      setUid(createPayload.data.product_uid)
    }
  }, [createPayload])

  useEffect(() => {
    if(createSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [createSuccess])

  useEffect(() => {
    if(createError) {
      Swal.fire({
        icon: "error",
        title: "Error Create Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorCreateMsg?.data.msg,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createError])

  useEffect(() => {
    if(updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [updateSuccess])

  useEffect(() => {
    if(updateError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updateError])

  const onAdd = (e: any) => {
    if(!isEditDashboard) {
      let params = {
        product_dashboard_link_name: e.dashboardName,
        product_dashboard_link_url: e.dashboardUrl,
        product_uid: uid
      }
      createDashboardLink(params)
    } else {
      let params = {
        product_dashboard_link_name: e.dashboardName,
        product_dashboard_link_url: e.dashboardUrl,
        product_uid: uid
      }
      updateDashboardLink({ body: params, product_dashboard_link_uid:  e.dashboardUid })
    }
    closeModal()
  };

  useEffect(() => {
    if(createDashboardLinkSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Dashboard Link",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [createDashboardLinkSuccess])

  useEffect(() => {
    if(createDashboardLinkError) {
      Swal.fire({
        icon: "error",
        title: "Error Create Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorCreateMsg?.data.msg,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createDashboardLinkError])

  useEffect(() => {
    if(updateDashboardLinkSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Dashboard Link",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [updateDashboardLinkSuccess])

  useEffect(() => {
    if(updateDashboardLinkError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updateDashboardLinkError])

  useEffect(() => {
    if(deleteDashboardLinkSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Delete Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Dashboard Link",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [deleteDashboardLinkSuccess])

  useEffect(() => {
    if(deleteDashboardLinkError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete Dashboard Link",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [deleteDashboardLinkError])

  const openModal = () => {
    if(uid != undefined) {
      setOpen(true)
      setIsEditDashboard(false)
      resetDashboard({
        dashboardName: "",
        // dashboardStatus: "",
        dashboardUrl: ""
      })
    } else {
    Swal.fire({
      icon: "error",
      title: "Can not add url dashoard",
      text: "Please create product first!",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#2e4054",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      setValue(0)
    })
    }
  }

  const closeModal = () => {
    setOpen(false)
    resetDashboard({
      dashboardName: "",
      dashboardUrl: ""
      // dashboardStatus: "",
    })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const handleFileChangeIcon = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = () => {
      setFileContentIcon(`${reader.result?.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, '')}`)
    };
  };

  const handleFileChangeBanner = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = () => {
      setFileContentBanner(`${reader.result?.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, '')}`)
    };
  };

  //#endregion

  //#region UI VIEW

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const customCellRender = (params: GridCellParams) => {

    const onEdit = (e: any) => {
      setOpen(true)
      setIsEditDashboard(true)
      getDashboardLinkDetail({ product_dashboard_link_uid: params.id })
    };

    const onDelete = (e: any) => {
      Swal.fire({
        title: "Delete URL Dashboard",
        text: "Are you sure want to delete URL dashboard?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2e4054",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus()
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDashboardLink({ product_dashboard_link_uid: params.id })
        }
      });
    };

    return (
      mode!=FieldMode.detail ? <>
      <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Edit
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </> : <Typography>
        No Available Actions
      </Typography>
    )
    
  };

  const columns: GridColDef[] = [
    {
      field: "number",
      headerName: "No.",
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "dashboardName",
      headerName: "Dashboard Name",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "dashboardUrl",
      headerName: "URL",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 250,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params: any) => {
    //     return (
    //       <>
    //         {params.row.status !== null ? (
    //           params.row.status.toLowerCase() === "active" ? (
    //             <Chip label="ACTIVE" color="success" variant="outlined" />
    //           ) : (
    //             <Chip label="INACTIVE" color="warning" variant="outlined" />
    //           )
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const title = () => {
    switch(mode) {
      case FieldMode.create: {
        return "Create Autentika Product";
      }
      case FieldMode.edit: {
        return "Update Autentika Product";
      }
      case FieldMode.detail: {
        return "Detail Autentika Product";
      }
    }
  }

  const buttons = () => {

    var saveBtn;
    if(mode == FieldMode.create || mode == FieldMode.edit) {
      saveBtn = value == 0
      ? <Button variant="contained" type="submit">
        Save
      </Button>
      : null
    } else {
      saveBtn = null
    }

    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/product-list")}>
            Back
        </Button>
        {saveBtn}
      </Stack>
    )
  }

  const tabPanel = () => {
    return <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="basic tabs example"
            sx={{
              fontWeight: 'bold'
            }}>
            <Tab label="Product" {...a11yProps(0)}/>
            <Tab label="Dashboard" {...a11yProps(1)}/>
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {productPanel()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {dashboardPanel()}
        </CustomTabPanel>
      </Box>
  }

  const productPanel = () => {
    return <>
    <Typography variant="h4" fontFamily="Open Sans" mb={3}>
      Product Information
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={6} md={6}>
      <InputForm
          name="productName"
          label="Product Name"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          placeholder="Type product name..."
          disabled={mode==FieldMode.detail}
        />
      <InputForm
          name="productCode"
          label="Product Code"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          placeholder="Type product code..."
          disabled={mode==FieldMode.detail}
        />
      <Typography variant="body16Regular">
          Product Icon
          <span style={{color: '#ef4545'}}> *</span>
        </Typography>
        { fileContentIcon != undefined && fileContentIcon != "" ?
          <div style={{ position: 'relative', width: 'auto', height: '120px', overflow: 'hidden', marginTop: '8px', marginBottom: '8px', border: '1px solid #ccc', borderRadius: '8px' }}>
            { mode !== FieldMode.detail ?
              <IconButton onClick={() => {
                setFileContentIcon(undefined)
                reset({ ...getValues(), productIcon: "" });
              }} style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', padding: '5px' }}>
                <Close />
              </IconButton> : null
            }
            <img src={`data:image/jpeg;base64,${fileContentIcon}`} alt="Product Icon" style={{ position: 'absolute', width: 'auto', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
          </div> : null
        }
        <FileForm
          name="productIcon"
          errors={errors}
          control={control}
          required
          placeholder="Type Here....."
          maxLength={100}
          isLoading={false}
          type="file"
          onChange={handleFileChangeIcon}
          disabled={mode==FieldMode.detail}
          maxSizeKB={64}
        />
        <Typography variant="body16Regular">Product Image Banner</Typography>
        { fileContentBanner != undefined && fileContentBanner != "" ?
          <div style={{ position: 'relative', width: 'auto', height: '120px', overflow: 'hidden', marginTop: '8px', marginBottom: '8px', border: '1px solid #ccc', borderRadius: '8px' }}>
            { mode !== FieldMode.detail ?
              <IconButton onClick={() => {
                setFileContentBanner(undefined)
                reset({ ...getValues(), productImageBanner: "" });
              }} style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', padding: '5px' }}>
                <Close />
              </IconButton> : null
            }
            <img src={`data:image/jpeg;base64,${fileContentBanner}`} alt="Product Icon" style={{ position: 'absolute', width: 'auto', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
          </div> : null
        }
        <FileForm
          name="productImageBanner"
          errors={errors}
          control={control}
          required
          placeholder="Type Here....."
          maxLength={100}
          isLoading={false}
          type="file"
          onChange={handleFileChangeBanner}
          disabled={mode==FieldMode.detail}
          maxSizeKB={400}
        />
        <SelectFormm
            name="productStatus"
            label="Product Status (Catalog View)"
            defaultValue={0}
            options={optStatus}
            placeholder="none"
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            required
            disabled={mode==FieldMode.detail}
          />
      </Grid>
      <Grid item xs={6} md={6}>
      <SelectFormm
          name="productCategory"
          label="Product Category"
          defaultValue={0}
          options={optSelect}
          placeholder="none"
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
          disabled={mode==FieldMode.detail}
        />
        <Box sx={{height: '6px'}}/>
        <InputForm
          name="productDocumentationLink"
          label="Product Documentation Link"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          placeholder="Type documantation link..."
          disabled={mode==FieldMode.detail}
          />
        <TextAreaForm
          name="productDescription"
          label="Product Description"
          defaultValue={""}
          control={control}
          errors={errors}
          required
          placeholder="Type product description..."
          disabled={mode==FieldMode.detail}
        />
      </Grid>
    </Grid>
    </>
  }

  const dashboardPanel = () => {
    return <>
    <Box sx={{ display: 'flex', p: 1 }} mb={1}>
      <Typography variant="h4" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
        URL Dashboard
      </Typography>
      { mode != FieldMode.detail ? <Button
        variant="contained"
        color="primary"
        onClick={() => openModal()}
        sx={{ height: '40px', width: '100px' }}>
          Add
      </Button> : null}
    </Box>
    <DataGrid
        rows={rowData}
        columns={columns}
        disableColumnMenu
        hideFooter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        autoHeight
      />
      {addDashboardModal}
    <Box sx={{height: '30px'}}/>
    </>
  }

  const addDashboardModal = (
    <Modal open={open}>
      <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '30px',
          width: '50%'
        }}
        component="form"
        onSubmit={handleDashboard(onAdd)}
        >
      <Box sx={{ display: 'flex' }} mb={1}>
        <Typography variant="h4" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
        Add Dashboard
        </Typography>
        <IconButton onClick={() => closeModal()}>
        <Close
          sx={{
            height:'20px',
            width:'20px',
            padding: '0px',
            alignSelf: 'center'
          }}
          />
        </IconButton>
      </Box>
      <Box sx={{height: '20px'}}/>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <InputForm
              name="dashboardName"
              label="Dashboard Name"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="dashboard name.."
            />
          {/* <RadioForm
              defaultValue={"ACTIVE"}
              name="dashboardStatus"
              label="Status"
              control={controlDashboard}
              errors={errorDasboard}
              required
              isLoading={false}
              options={optStatus}
          /> */}
          </Grid>
          <Grid item xs={6} md={6}>
          <InputForm
              name="dashboardUrl"
              label="Dashboard URL"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="dashboard url.."
            />
          </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <Button
          sx={{marginLeft: 'auto', marginRight: '8px' }}
          variant="contained"
          color="gray"
          onClick={() => closeModal()}>
            Cancel
        </Button>
        <Button variant="contained" onClick={handleDashboard(async (e: any) => onAdd(e))}>
          Save
        </Button>
      </Box>
     </Box>
    </Modal>
  )

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
    isLoading={isLoadingCategory || isLoadingProduct}
    isLoadingEvent={createLoading || updateLoading || createDashboardLinkLoading || updateDashboardLinkLoading || deleteDashboardLinkLoading || isLoadingDashboardLink}
    inputCount={6}
    titleWidth={'350px'}
    >
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {title()}
      </Typography>
      {tabPanel()}
      {buttons()}
    </Box>
    </DetailDataSkeleton>
  );

  //#endregion

};

export default ProductSubscriptionField;

import * as yup from "yup";
import {
  validationNotEmpty,
} from "./defaultValidations";

export default yup.object({
  paymentDate: validationNotEmpty("Payment Date"),
  attachment: validationNotEmpty("Attachment"),
  remarks: validationNotEmpty("Remarks"),
  status: validationNotEmpty("Status"),
});

import { createSlice } from "@reduxjs/toolkit";
import { productListApiSlice } from "../service/productListApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false
};

const productListSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        productListApiSlice.endpoints.createProductList.matchFulfilled,
        state => {
          state.messageSuccess = 'Product Success Created';
        }
      )
    }
})

export const productListReducer = productListSlice.reducer
import IRoutes from "../interfaces/IRoutes";
import BillingInvoice from "../pages/billingInvoice/BillingInvoice";
import BillingInvoiceField from "../pages/billingInvoice/BillingInvoiceField";

const BillingInvoiceRoute: Array<IRoutes> = [
    {
        element: <BillingInvoice />,
        path: "/billing-invoice",
    },
    {
        element: <BillingInvoiceField />,
        path: "/billing-invoice/create",
    },
    {
        element: <BillingInvoiceField />,
        path: "/billing-invoice/edit/:id",
    },
    {
        element: <BillingInvoiceField />,
        path: "/billing-invoice/detail/:id",
    }
];

export { BillingInvoiceRoute };

import { apiSlice } from "../api/apiSlice";

export const billingInvoiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ADMIN USER
    getAllBilling: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/billings`,
          params: params,
        };
      },
      providesTags: ["GetAllBilling"],
    }),
    getBillingById: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/billing/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetBillingById"],
    }),
    createBilling: builder.mutation<any, any>({
      query: (body) => ({
        url: "/billing",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CreateBilling", "GetAllBilling"],
    }),
    updateBilling: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `/billing/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateBilling', 'GetAllBilling']
    }),
    // updateUserAdminPassword: builder.mutation<any, any>({
    //   query: ({body, user_admin_uid}) => ({
    //     url: `/user-admin-password/${user_admin_uid}`,
    //     method: "PUT",
    //     body
    //   }),
    //   invalidatesTags: ['UpdateUserAdminPassword', 'GetAllUserAdmin']
    // }),
    // deleteUserAdmin: builder.mutation<any, any>({
    //   query: ({ user_admin_uid }) => ({
    //     url: `/user-admin/${user_admin_uid}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["DeleteUserAdmin", "GetAllUserAdmin"],
    // }),

    // // ADMIN ROLES
    // getAllRoles: builder.query<any, any>({
    //   query: () => {
    //     return {
    //       url: `/roles`,
    //     };
    //   },
    //   providesTags: ["GetAllRoles"],
    // }),
  }),
});

export const {
  useGetAllBillingQuery,
  useGetBillingByIdMutation,
  useCreateBillingMutation,
  useUpdateBillingMutation,
  // useUpdateUserAdminPasswordMutation,
  // useDeleteUserAdminMutation,
  // useGetAllRolesQuery
} = billingInvoiceApiSlice;

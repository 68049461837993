import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import addDashboard from "../../../utils/validation/addDashboard";
import { InputForm } from "../forms";
import FileForm from "../forms/fileForm";
import Swal from "sweetalert2";
import { RootState, useAppSelector } from "../../../store";
import { FieldMode } from "../../../constants/enum/FieldMode";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCompanyIdMutation,
  useUpdateCompanyDocumentMutation,
} from "../../../store/feature/service/companyListApiSlice";

const DocumentNeeded = ({ setValue, mode, compIdData }: any) => {
  const navigate = useNavigate();
  const idParam = useParams();
  const [fileContentIcon, setFileContentIcon] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileContentB, setFileContentB] = useState("");

  const { companyUid } = useAppSelector(
    (store: RootState) => store.companyList
  );

  const [
    updateCompanyDocument,
    { data, isLoading, isSuccess, isError, error },
  ]: any = useUpdateCompanyDocumentMutation();

  const [
    getCompanyId,
    {
      data: dataCpnId,
      isSuccess: succesCpnId,
      isError: errorCpnId,
      error: errDataCpnId,
    },
  ]: any = useGetCompanyIdMutation();

  const {
    control,
    // handleSubmit,
    setValue: setValDoc,
    formState: { errors },
    watch,
  } = useForm<{
    documentA: string;
    documentB: string;
  }>({
    reValidateMode: "onChange",
    // resolver: yupResolver(addDashboard),
    defaultValues: {
      documentA: "",
      documentB: "",
    },
  });

  const handleFileChangeIcon = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      setFileContentIcon(
        `${reader.result
          ?.toString()
          .replace(/^data:image\/(png|jpeg|jpg);base64,/, "")}`
      );
    };
  };

  const onSubmit = () => {
    const bodyCmp: { [key: string]: any } = {};

    if (fileContent.length) {
      bodyCmp.company_offreqdoc = fileContent;
    }

    if (fileContentB.length) {
      bodyCmp.company_regreqdoc = fileContentB;
    }

    console.log("mantap ===> ", bodyCmp);
    updateCompanyDocument({ id: idParam.id, body: bodyCmp });
  };

  useEffect(() => {
    if (companyUid || idParam.id !== undefined) {
      // getCompanySubcriptionCmpId({id:companyUid})
      // console.log('company subs ==> ', companyUid)
    } else {
      Swal.fire({
        icon: "error",
        title: "Can not upload Document",
        text: "Please create General Information first!",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#2e4054",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        setValue(0);
      });
    }
  }, [companyUid]);

  const handleFileChange = (file: any) => {
    console.log("file A ===> ", file);
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      console.log("===> ", event.target.result.split(",")[1]);
      setFileContent(event.target.result.split(",")[1]);
    };
    fileReader.readAsDataURL(file[0]);
  };

  const base64ToPdf = (base64String: any, fileName: string) => {
    // Decode the Base64 string
    const binaryString = window.atob(base64String);

    console.log("binry string ===> ", binaryString);
    // Convert binary string to byte array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger a click event on the link
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    if (fileContent) {
      base64ToPdf(fileContent, "registration-document.pdf");
    } else {
      alert("No file content to download");
    }
  };

  const handleDownloadB = () => {
    console.log("from B content ==> ", fileContentB);
    if (fileContentB) {
      base64ToPdf(fileContentB, "company-requirement-document.pdf");
    } else {
      alert("No file content to download");
    }
  };

  const handleFileChangeB = (file: any) => {
    console.log("file B ===> ", file);
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      console.log("===> ", event.target.result.split(",")[1]);
      setFileContentB(event.target.result.split(",")[1]);
    };
    fileReader.readAsDataURL(file[0]);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Upload Document",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Upload Document",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => navigate("/company-list"));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (mode === FieldMode.detail) {
      getCompanyId({ id: idParam.id });
      setValDoc("documentA", "registration-document.pdf");
      setValDoc("documentB", "company-requirement-document.pdf");
    }
  }, []);

  useEffect(() => {
    if (mode === FieldMode.detail) {
      console.log("cpn_offreqdoc ==> ", dataCpnId?.data?.cpn_offreqdoc);
      if (dataCpnId?.data?.cpn_offreqdoc) {
        setFileContent(dataCpnId?.data?.cpn_offreqdoc);
      }
      if (dataCpnId?.data?.cpn_regreqdoc) {
        setFileContentB(dataCpnId?.data?.cpn_regreqdoc);
      }
    }
  }, [dataCpnId]);

  const space = mode === FieldMode.detail ? 9 : 12;

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h4" fontFamily="Open Sans" my={5}>
              Document Needed
            </Typography>
          </Grid>
          {((fileContent.length !== 0 && mode === FieldMode.detail) ||
            mode !== FieldMode.detail) && (
            <Grid item xs={space} sm={space} md={space} lg={space}>
              <InputForm
                name="documentA"
                label="Document A"
                errors={errors}
                control={control}
                maxLength={100}
                isLoading={false}
                placeholder="Type PIC Name..."
                type={mode === FieldMode.detail ? "text" : "file"}
                disabled={mode === FieldMode.detail}
                onChange={handleFileChange}
              />
            </Grid>
          )}
          {/* {mode !== FieldMode.detail && (
            <Grid item xs={space} sm={space} md={space} lg={space}>
              <InputForm
                name="documentA"
                label="Document A"
                errors={errors}
                control={control}
                maxLength={100}
                isLoading={false}
                placeholder="Type PIC Name..."
                type={mode === FieldMode.detail ? "text" : "file"}
                disabled={mode === FieldMode.detail}
                onChange={handleFileChange}
              />
            </Grid>
          )} */}
          {mode === FieldMode.detail && fileContent && (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              display="flex"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={handleDownload}
                disabled={fileContent.length === 0}
              >
                Download File
              </Button>
            </Grid>
          )}
          {((fileContentB.length !== 0 && mode === FieldMode.detail) ||
            mode !== FieldMode.detail) && (
            <Grid item xs={space} sm={space} md={space} lg={space}>
              <InputForm
                name="documentB"
                label="Document B"
                errors={errors}
                control={control}
                maxLength={100}
                isLoading={false}
                placeholder="Type PIC Name..."
                type={mode === FieldMode.detail ? "text" : "file"}
                disabled={mode === FieldMode.detail}
                onChange={handleFileChangeB}
              />
            </Grid>
          )}
          {mode === FieldMode.detail && fileContentB && (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              display="flex"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={handleDownloadB}
                disabled={fileContentB.length === 0}
              >
                Download File
              </Button>
            </Grid>
          )}
          {mode !== FieldMode.detail && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{ marginLeft: "auto", marginRight: "8px" }}
                  variant="contained"
                  color="gray"
                  onClick={() => navigate("/company-list")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  disabled={
                    watch("documentB").length === 0 &&
                    watch("documentA").length === 0
                  }
                >
                  Save
                </Button>
              </Box>
            </Grid>
          )}
          {mode === FieldMode.detail && !fileContentB && !fileContent && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="center"
              mt="200px"
            >
              <Typography variant="h4" fontFamily="Open Sans" my={5}>
                No Document Uploaded
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default DocumentNeeded;

import { Box, Grid, Typography } from "@mui/material";
import { InputForm, SelectFormm, TextAreaForm } from "../forms";
import { FieldMode } from "../../../constants/enum/FieldMode";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetCompanyIdMutation, useGetCompanySubcriptionCmpIdMutation } from "../../../store/feature/service/companyListApiSlice";
import { setCompanyUid } from "../../../store/feature/reducer/companyListReducer";
import { optBusinessField } from "./optBusinessField";

const CompanyPanel = ({
  companyUid,
  errors,
  control,
  mode,
  reset,
  setValue,
  setFileContentIcon,
  setFileContentBanner,
  optSelect,
}: any) => {
  const idParam = useParams();
  const optStatus = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];
  
  const [getCompanyId, { data: compIdData, isLoading: successCompId }] = useGetCompanyIdMutation();

  const [
    getCompanySubcriptionCmpId,
    { data: companySubcriptionList, isSuccess: successCompSubsId },
  ] = useGetCompanySubcriptionCmpIdMutation();

  useEffect(() => {
    if (mode !== FieldMode.create) {
      if (idParam.id !== undefined) {
        getCompanyId({ id: idParam.id });
      }
    }
  }, [mode, idParam.id, getCompanyId]);

  useEffect(() => {
    if (compIdData) {
      const data = compIdData?.data;
      setValue("companyName", data.cpn_name);
      setValue("mobile", data.cpn_phone_number);
      setValue("businessField", data.cpn_industry);
      setValue("vatNumber", data.cpn_vatnbr);
      setValue("companyStatus", data.cpn_status.toUpperCase());
      setValue("companyEmail", data.cpn_email);
      setValue("companyAddress", data.cpn_address);
      setValue("note", data.cpn_description);
      setValue("picName", data.cpn_pic1name);
      setValue("mobilePic", data.cpn_pic1phone1);
      setValue("picEmail", data.cpn_pic1email);
      setValue("picRole", data.cpn_pic1position);
      setCompanyUid(data.cpn_uid)
      getCompanySubcriptionCmpId({ id: idParam.id });
      console.log('haha ===> ', idParam.id)
    }
  }, [compIdData]);

  return (
    <>
      <Typography variant="h4" fontFamily="Open Sans" mb={3}>
        Company Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <InputForm
            name="companyName"
            label="Company Name"
            errors={errors}
            control={control}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type company name..."
            disabled={mode == FieldMode.detail}
          />
          <InputForm
            name="mobile"
            label="Company Phone Number"
            control={control}
            errors={errors}
            maxLength={100}
            isLoading={false}
            type="number"
            placeholder="Type PIC Phone number.."
            disabled={mode === FieldMode.detail}
            showStartAdornment
            startAdornment={"+62"}
            sxEndAdornment={{
              backgroundColor: "inherit",
              border: "none",
              cursor: "pointer",
              width: "4rem",
            }}
            required
          />
          <SelectFormm
            name="businessField"
            label="Business Field"
            defaultValue={0}
            options={optBusinessField}
            placeholder="none"
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            required
            disabled={mode == FieldMode.detail}
          />
          <InputForm
            name="vatNumber"
            label="VAT Number"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type VAT Number..."
            disabled={mode == FieldMode.detail}
          />
          <SelectFormm
            name="companyStatus"
            label="Company Status"
            defaultValue={0}
            options={optStatus}
            placeholder="none"
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            required
            disabled={mode == FieldMode.detail}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <InputForm
            name="companyEmail"
            label="Company Email"
            errors={errors}
            control={control}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type Company Address..."
            disabled={mode == FieldMode.detail}
          />
          <Box sx={{ height: "6px" }} />
          <InputForm
            name="companyAddress"
            label="Company Address"
            errors={errors}
            control={control}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type Company Address..."
            disabled={mode == FieldMode.detail}
          />
          <TextAreaForm
            name="note"
            label="Note"
            defaultValue={""}
            control={control}
            errors={errors}
            placeholder="Type Note..."
            disabled={mode == FieldMode.detail}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" fontFamily="Open Sans" my={5}>
        PIC Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <InputForm
            name="picName"
            label="PIC Name"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type PIC Name..."
            disabled={mode == FieldMode.detail}
            required
          />
          <InputForm
            name="mobilePic"
            label="PIC Phone Number"
            control={control}
            errors={errors}
            maxLength={100}
            isLoading={false}
            type="number"
            placeholder="Type PIC Phone number.."
            disabled={mode === FieldMode.detail}
            showStartAdornment
            startAdornment={"+62"}
            sxEndAdornment={{
              backgroundColor: "inherit",
              border: "none",
              cursor: "pointer",
              width: "4rem",
            }}
            required
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <InputForm
            name="picEmail"
            label="PIC Email"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type PIC Email..."
            disabled={mode == FieldMode.detail}
            required
          />
          <InputForm
            name="picRole"
            label="PIC Role"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type PIC Role..."
            disabled={mode == FieldMode.detail}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyPanel;

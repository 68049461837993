import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { FieldMode } from "../../constants/enum/FieldMode";
import Swal from "sweetalert2";
import userAdmin from "../../utils/validation/userAdmin";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useCreateUserAdminMutation, useGetAllRolesQuery, useGetUserAdminByIdMutation, useUpdateUserAdminMutation, useUpdateUserAdminPasswordMutation } from "../../store/feature/service/userAdminApiSlice";
import { encrypt } from "../../utils/hash/password";
import userAdminUpdate from "../../utils/validation/userAdminUpdate";

const UserAdminField = () => {

  //#region INIT

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const [mode, setMode] = useState(FieldMode.create);
  const [isShowPass, setIsShowPass] = useState(false);
  const { data: roles, isLoading: isLoadingRoles } = useGetAllRolesQuery({});

  const tempRoles: any = roles !== undefined ? roles?.data?.data : []

  const optRoleGroup = tempRoles.map((item: any) => (
    {
      label: item.adm_role_name,
      value: item.adm_role_uid,
    }
  ))

  const optStatus = [
    {
      label: "ACTIVE",
      value: "ACTIVE"
    },
    {
      label: "INACTIVE",
      value: "INACTIVE"
    },
  ]

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const [
    getUserAdminDetail,
    {
      data: userAdminDetail,
      isLoading: isLoadingUserAdminDetail
    },
  ]: any = useGetUserAdminByIdMutation();

  const [
    createUserAdmin,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      error: errorCreateMsg,
    },
  ]: any = useCreateUserAdminMutation();

  const [
    updateUserAdmin,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
      error: errorUpdateMsg,
    },
  ]: any = useUpdateUserAdminMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    email: string;
    password: string;
    confirmationPassword: string;
    lastName: string;
    mobile: string;
    status: string;
    roleGroup: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(mode == FieldMode.create ? userAdmin : userAdminUpdate),
    defaultValues: {
      firstName: "",
      email: "",
      password: "",
      confirmationPassword: "",
      lastName: "",
      mobile: "",
      status: "",
      roleGroup: "",
    },
  });

  useEffect(() => {
    if(pathname.includes("create")) {
      setMode(FieldMode.create);
    } else if (pathname.includes("edit")) {
      setMode(FieldMode.edit);
    } else if (pathname.includes("detail")) {
      setMode(FieldMode.detail);
    }
  }, []);
  
  useEffect(() => {
    if(mode !== FieldMode.create) {
      if (idParam.id !== undefined) {
        getUserAdminDetail({ user_admin_uid: idParam.id})
      }
    }
  }, [mode]);

  useEffect(() => {
    if(userAdminDetail != undefined) {
      reset({
        firstName: userAdminDetail.data?.adm_user_firstname,
        email: userAdminDetail.data?.adm_user_email,
        password: "",
        confirmationPassword: "",
        lastName: userAdminDetail.data?.adm_user_lastname,
        mobile: userAdminDetail.data?.adm_user_mobile,
        status: userAdminDetail.data?.adm_user_status.toUpperCase(),
        roleGroup: userAdminDetail.data?.adm_role_uid,
      })
    }
  }, [userAdminDetail]);

  const onSubmit = (e: any) => {
    if(mode == FieldMode.create) {
      let params = {
        adm_role_uid: e.roleGroup,
        adm_user_email: e.email,
        adm_user_firstname: e.firstName,
        adm_user_lastname: e.lastName,
        adm_user_mobile: e.mobile,
        adm_user_pwd_hash: encrypt(e.password),
        adm_user_status: e.status.toLowerCase()
      }
      createUserAdmin(params);
    } else if(mode == FieldMode.edit) {
      let params = {
        adm_role_uid: e.roleGroup,
        adm_user_email: e.email,
        adm_user_firstname: e.firstName,
        adm_user_lastname: e.lastName,
        adm_user_mobile: e.mobile,
        adm_user_status: e.status.toLowerCase()
      }
      updateUserAdmin({ body: params, user_admin_uid: idParam.id });
    }
  };

  useEffect(() => {
    if(createSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/user-admin")
      });
    }
  }, [createSuccess])

  useEffect(() => {
    if(createError) {
      Swal.fire({
        icon: "error",
        title: "Error Create User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Create User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createError])

  useEffect(() => {
    if(updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/user-admin")
      });
    }
  }, [updateSuccess])

  useEffect(() => {
    if(updateError) {
      Swal.fire({
        icon: "error",
        title: "Error Update User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updateError])


  //#endregion

  //#region UI VIEW

  const title = () => {
    var title = "";
    if(mode == FieldMode.create) {
      title = "Create User Admin";
    } else if(mode == FieldMode.edit) {
      title = "Update User Admin";
    } else if(mode == FieldMode.detail) {
      title = "Detail User Admin";
    }
    return <Typography variant="h3" fontFamily="Open Sans" mb={3}>
      {title}
    </Typography>
  }

  const buttons = () => {
    var saveBtn;
    if(mode == FieldMode.create || mode == FieldMode.edit) {
      saveBtn = <Button variant="contained" type="submit">
        Save
      </Button>
    } else {
      saveBtn = null
    }
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/user-admin")}>
            Back
        </Button>
        {saveBtn}
      </Stack>
    )
  }
  
  const contents = () => {
    return <>
    <Typography variant="h5" fontWeight="bold" fontFamily="Open Sans" mb={3}>
      Admin Information
    </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <InputForm
              name="firstName"
              label="First Name"
              control={control}
              errors={errors}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Type first name.."
              disabled={mode==FieldMode.detail}
            />
          <InputForm
            name="email"
            label="Email"
            control={control}
            errors={errors}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type email.."
            disabled={mode==FieldMode.detail}
          />
          <SelectFormm
            name="status"
            label="Status"
            defaultValue={0}
            options={optStatus}
            placeholder="none"
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            required
            disabled={mode==FieldMode.detail}
          />
          {
            mode == FieldMode.create ?
              <InputForm
                name="password"
                label="Password"
                control={control}
                errors={errors}
                required
                maxLength={100}
                isLoading={false}
                type={isShowPass ? 'text' : 'password'}
                placeholder="Type password.."
                showEndAdornment
                sxEndAdornment={{
                  backgroundColor: 'inherit',
                  border: 'none',
                  cursor: 'pointer',
                  width: '4rem',
                }}
                endAdornment={endAdornment}
                onClickEndAdornment={toggleShowPassword}
              /> : null
          }
        </Grid>
        <Grid item xs={6} md={6}>
        <InputForm
            name="lastName"
            label="Last Name"
            control={control}
            errors={errors}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type last name.."
            disabled={mode==FieldMode.detail}
          />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '60px' }}>
            <InputForm
              name="mobileCountry"
              label="Mobile"
              control={control}
              errors={errors}
              maxLength={100}
              isLoading={false}
              placeholder="+62"
              disabled={true}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px', marginTop: '32px' }}>
            <InputForm
              name="mobile"
              label=""
              control={control}
              errors={errors}
              required
              maxLength={100}
              isLoading={false}
              type="number"
              placeholder="Type mobile number.."
              disabled={mode === FieldMode.detail}
            />
          </div>
        </div>
        <SelectFormm
          name="roleGroup"
          label="Role Group"
          defaultValue={0}
          options={optRoleGroup}
          placeholder="none"
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
          disabled={mode==FieldMode.detail}
        />
        {
            mode == FieldMode.create ?
            <InputForm
              name="confirmationPassword"
              label="Confirmation Password"
              control={control}
              errors={errors}
              required
              maxLength={100}
              isLoading={false}
              type={isShowPass ? 'text' : 'password'}
              placeholder="Type confirmation password.."
              showEndAdornment
              sxEndAdornment={{
                backgroundColor: 'inherit',
                border: 'none',
                cursor: 'pointer',
                width: '4rem',
              }}
              endAdornment={endAdornment}
              onClickEndAdornment={toggleShowPassword}
            /> : null
          }
        </Grid>
      </Grid>
    <Box sx={{height: '30px'}}/>
    </>
  }

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
    isLoading={isLoadingRoles || isLoadingUserAdminDetail}
    isLoadingEvent={false}
    inputCount={6}
    titleWidth={'350px'}
    >
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      {title()}
      {contents()}
      {buttons()}
    </Box>
    </DetailDataSkeleton>
  );

  //#endregion

};

export default UserAdminField;
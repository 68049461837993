import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm } from "../../components/molecules/forms/index";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { FieldMode } from "../../constants/enum/FieldMode";
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useUpdateUserAdminPasswordMutation } from "../../store/feature/service/userAdminApiSlice";
import { encrypt } from "../../utils/hash/password";
import userAdminUpdatePassword from "../../utils/validation/userAdminUpdatePassword";

const UserAdminUpdatePassword = () => {

  //#region INIT

  const navigate = useNavigate();
  const idParam = useParams();
  const [isShowPass, setIsShowPass] = useState(false);

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const [
    updateUserAdminPassword,
    {
      data: updatePasswordPayload,
      isLoading: updatePasswordLoading,
      isSuccess: updatePasswordSuccess,
      isError: updatePasswordError,
      error: errorUpdatePasswordMsg,
    },
  ]: any = useUpdateUserAdminPasswordMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    password: string;
    confirmationPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(userAdminUpdatePassword),
    defaultValues: {
      password: "",
      confirmationPassword: "",
    },
  });

  const onSubmit = (e: any) => {
    if(e.password !== "") {
      let paramPwd = {
        update_password: encrypt(e.password)
      }
      updateUserAdminPassword({ body: paramPwd, user_admin_uid: idParam.id });
    }
  };

  useEffect(() => {
    if(updatePasswordSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Password User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Password User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/user-admin")
      });
    }
  }, [updatePasswordSuccess])

  useEffect(() => {
    if(updatePasswordError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Password User Admin",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Password User Admin",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updatePasswordError])


  //#endregion

  //#region UI VIEW

  const title = () => {
    return <Typography variant="h3" fontFamily="Open Sans" mb={3}>
      Update Password
    </Typography>
  }

  const buttons = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/user-admin")}>
            Back
        </Button>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </Stack>
    )
  }
  
  const contents = () => {
    return <>
      <InputForm
        name="password"
        label="Password"
        control={control}
        errors={errors}
        required
        maxLength={100}
        isLoading={false}
        type={isShowPass ? 'text' : 'password'}
        placeholder="Type password.."
        showEndAdornment
        sxEndAdornment={{
          backgroundColor: 'inherit',
          border: 'none',
          cursor: 'pointer',
          width: '4rem',
        }}
        endAdornment={endAdornment}
        onClickEndAdornment={toggleShowPassword}
      />
    <InputForm
        name="confirmationPassword"
        label="Confirmation Password"
        control={control}
        errors={errors}
        required
        maxLength={100}
        isLoading={false}
        type={isShowPass ? 'text' : 'password'}
        placeholder="Type confirmation password.."
        showEndAdornment
        sxEndAdornment={{
          backgroundColor: 'inherit',
          border: 'none',
          cursor: 'pointer',
          width: '4rem',
        }}
        endAdornment={endAdornment}
        onClickEndAdornment={toggleShowPassword}
      />
    <Box sx={{height: '30px'}}/>
    </>
  }

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
    isLoading={false}
    isLoadingEvent={updatePasswordLoading}
    inputCount={6}
    titleWidth={'350px'}
    >
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      {title()}
      {contents()}
      {buttons()}
    </Box>
    </DetailDataSkeleton>
  );

  //#endregion

};

export default UserAdminUpdatePassword;
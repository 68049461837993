import { createSlice } from "@reduxjs/toolkit";
import { userAdminApiSlice } from "../service/userAdminApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false
};

const userAdminSlice = createSlice({
    name: 'userAdmin',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        userAdminApiSlice.endpoints.createUserAdmin.matchFulfilled,
        state => {
          state.messageSuccess = 'Product Success Created';
        }
      )
    }
})

export const userAdminReducer = userAdminSlice.reducer
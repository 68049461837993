import { apiSlice } from "../api/apiSlice";

export const pricingConfigApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // PRICING SCHEMA
    getAllPricingSchema: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/pricing/schema`,
          params: params,
        };
      },
      providesTags: ["GetAllPricingSchema"],
    }),
    getPricingSchemaById: builder.mutation<any, any>({
      query: ({ pricing_schema_uid }) => {
        return {
          url: `/pricing/schema/${pricing_schema_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPricingSchemaById"],
    }),
    createPricingSchema: builder.mutation<any, any>({
      query: (body) => ({
        url: "/pricing/schema",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreatePricingSchema", "GetAllPricingSchema"],
    }),
    updatePricingSchema: builder.mutation<any, any>({
      query: ({body, pricing_schema_uid}) => ({
        url: `/pricing/schema/${pricing_schema_uid}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdatePricingSchema', 'GetAllPricingSchema']
    }),
    deletePricingSchema: builder.mutation<any, any>({
      query: ({ pricing_schema_uid }) => ({
        url: `/pricing/schema/${pricing_schema_uid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeletePricingSchema", "GetAllPricingSchema"],
    }),
    getPricingSchemaByProductId: builder.mutation<any, any>({
      query: ({ product_uid }) => {
        return {
          url: `/pricing/schema/product/${product_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPricingSchemaByProductId"],
    }),

    // PLAN CONFIG
    getPlanConfigById: builder.mutation<any, any>({
      query: ({ pricing_plan_config_uid }) => {
        return {
          url: `/pricing/plan-config/${pricing_plan_config_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPlanConfigById"],
    }),
    createPlanConfig: builder.mutation<any, any>({
      query: (body) => ({
        url: "/pricing/plan-config",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreatePlanConfig", "GetPlanConfigBySchemaId"],
    }),
    updatePlanConfig: builder.mutation<any, any>({
      query: ({body, pricing_plan_config_uid}) => ({
        url: `/pricing/plan-config/${pricing_plan_config_uid}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdatePlanConfig', 'GetPlanConfigBySchemaId']
    }),
    deletePlanConfig: builder.mutation<any, any>({
      query: ({ pricing_plan_config_uid }) => ({
        url: `/pricing/plan-config/${pricing_plan_config_uid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeletePlanConfig", "GetPlanConfigBySchemaId"],
    }),
    getPlanConfigBySchemaId: builder.mutation<any, any>({
      query: ({ pricing_schema_uid }) => {
        return {
          url: `/pricing/plan-config/pricing-schema/${pricing_schema_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPlanConfigBySchemaId"],
    }),

  }),
});

export const {
  useGetAllPricingSchemaQuery,
  useGetPricingSchemaByIdMutation,
  useCreatePricingSchemaMutation,
  useUpdatePricingSchemaMutation,
  useDeletePricingSchemaMutation,
  useGetPricingSchemaByProductIdMutation,
  useGetPlanConfigByIdMutation,
  useCreatePlanConfigMutation,
  useUpdatePlanConfigMutation,
  useDeletePlanConfigMutation,
  useGetPlanConfigBySchemaIdMutation,
} = pricingConfigApiSlice;

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import UserAdminSearch from "../../components/molecules/userAdmin/UserAdminSearch";
import UserAdminTable from "../../components/molecules/userAdmin/UserAdminTable";
import { useGetAllUserAdminQuery } from "../../store/feature/service/userAdminApiSlice";

const UserAdmin = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAllUserAdminQuery(params);

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
          >
          User Admin
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        </Box>
        <UserAdminSearch params={params} setParams={setParams} />
        <UserAdminTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default UserAdmin;

import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteUserAdminMutation } from "../../../store/feature/service/userAdminApiSlice";

const BillingInvoiceTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    deleteUserAdmin,
    {
      data: deletePayload,
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
    },
  ] = useDeleteUserAdminMutation();

  if (deleteSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Delete Admin",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Delete Admin",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    const onDetail = (e: any) => {
      navigate(`/billing-invoice/detail/${params.id}`);
    };

    const onUpdatePassword = (e: any) => {
      navigate(`/billing-invoice/update-password/${params.id}`);
    };

    const onEdit = (e: any) => {
      navigate(`/billing-invoice/edit/${params.id}`);
    };

    const onDelete = (e: any) => {
      Swal.fire({
        title: "Delete Admin",
        text: "Are you sure want to delete admin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2e4054",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserAdmin({ user_admin_uid: params.id });
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
          {params.row.status === 'open' &&(<Button variant="contained" onClick={onEdit}>
            Edit
          </Button>)}
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billingNumber",
      headerName: "Billing Number",
      flex: 1,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dateIssued",
      headerName: "Date Issued",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "open" ? (
                <Chip label="OPEN" color="success" variant="outlined" />
              ) : params.row.status.toLowerCase() === "cancel" ? (
                <Chip label="CANCEL" color="error" variant="outlined" />
              ) : (
                <Chip
                  label={params.row.status.toUpperCase()}
                  color="warning"
                  variant="outlined"
                />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: 'center',
      minWidth: 250,
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [];

  // REGION: INIT ROWS
  const rows = tempData.map((row: any, index: number) => ({
    id: row.cpn_bill_uid,
    companyName: row.cpn_bill_company_name,
    billingNumber: row.cpn_bill_uid,
    dateIssued: row.cpn_bill_created_at,
    dueDate: row.cpn_bill_due_date,
    status: row.cpn_bill_status,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default BillingInvoiceTable;

import React from 'react';
import {
  Box,
  FormControl,
  SxProps,
  Theme,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

import FormLabel from '../../../atoms/formLabel/index';
import { StyledBox, StyledInput } from './elements';
import Swal from 'sweetalert2';

interface IProps {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  maxLength?: number;
  disabled?: boolean;
  type?: string;
  onChange?: Function;
  required?: boolean;
  placeholder?: string;
  isUpperCase?: boolean;
  specificName?: string;
  isLoading?: boolean;
  showStartAdornment?: boolean;
  showEndAdornment?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  sxEndAdornment?: any;
  onClickEndAdornment?: Function;
  maxSizeKB?: number;
}

export default function FileForm(props: IProps) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    errors,
    maxLength = 100,
    disabled,
    type = 'file',
    onChange,
    required = false,
    placeholder = '',
    isUpperCase = false,
    specificName = '',
    isLoading,
    showStartAdornment,
    showEndAdornment,
    startAdornment = <Typography variant="body16Regular">%</Typography>,
    endAdornment = <Typography variant="body16Regular">%</Typography>,
    sxEndAdornment,
    onClickEndAdornment = () => {},
    maxSizeKB
  } = props;
  const { palette } = useTheme();

  return (
    <FormControl sx={{ flex: 1, display: 'flex', mb: '1.5rem', ...sxFC }} variant="standard">
      {label?.length !== 0 && (
        <FormLabel htmlFor={name} sx={{ color: palette.neutral.dark, ...sxFL }}>
          {label}
          {!required && !disabled && (
            <span
              style={{
                marginLeft: '4px',
                color: palette.neutral.light,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontStyle: 'italic',
              }}
            >
              (optional)
            </span>
          )}
          {required && label !== undefined && !disabled && (
             <span style={{color: '#ef4545'}}> *</span>
          )}
        </FormLabel>
      )}
      {!isLoading && (
        <Controller
          defaultValue=""
          control={control}
          name={name}
          render={({ field }) => (
            <Box sx={{ position: 'relative', width: '100%' }}>
              <StyledInput
                {...field}
                type="file"
                sx={{ display: 'none' }}
                id={name}
                disabled={disabled}
                value={""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if(maxSizeKB !== null && maxSizeKB !== undefined) {
                    const file = e.target.files?.[0];
                    if (file) {
                      const fileSizeKB = file.size / 1024;
                      if (maxSizeKB && fileSizeKB > maxSizeKB) {
                        Swal.fire({
                          icon: "error",
                          title: "File size exceeds",
                          text: `File size exceeds the maximum allowed size of ${maxSizeKB} KB.`,
                          showCancelButton: false,
                          confirmButtonText: "OK",
                          confirmButtonColor: "#2e4054",
                          didOpen: () => Swal.getConfirmButton()?.focus(),
                        })
                      } else {
                        field.onChange(e.target.value);
                        onChange?.(e.target.files);
                      }
                    }
                  } else {
                    field.onChange(e.target.value);
                    onChange?.(e.target.files);
                  }
                }}
              />
              <Box
                component="label"
                htmlFor={name}
                sx={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  backgroundColor: palette.gray.light,
                  borderRadius: '4px',
                  textAlign: 'center',
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginTop: '8px'
                }}
              >
                <Typography variant="body16Regular">
                  Choose File
                </Typography>
              </Box>
            </Box>
          )}
        />
      )}
      {errors && errors[name] && (
        <Typography sx={{ color: 'red', mt: 1, fontSize: '14px' }}>
          Please choose a file.
        </Typography>
      )}
      {isLoading && (
        <Box>
          <Skeleton
            style={{
              height: '40px',
              borderRadius: '4px',
            }}
          />
        </Box>
      )}
    </FormControl>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { pricingConfigApiSlice } from "../service/pricingConfigApiSlice";

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoadingEvent: Boolean
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  isLoadingEvent: false
};

const pricingConfigSlice = createSlice({
    name: 'pricingConfig',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addMatcher(
        pricingConfigApiSlice.endpoints.createPricingSchema.matchFulfilled,
        state => {
          state.messageSuccess = 'Pricing Schema Success Created';
        }
      )
    }
})

export const pricingConfigReducer = pricingConfigSlice.reducer
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { FieldMode } from "../../constants/enum/FieldMode";
import Swal from "sweetalert2";
import userAdmin from "../../utils/validation/userAdmin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  useCreateUserAdminMutation,
  useGetAllRolesQuery,
  useGetUserAdminByIdMutation,
  useUpdateUserAdminMutation,
  useUpdateUserAdminPasswordMutation,
} from "../../store/feature/service/userAdminApiSlice";
import { encrypt } from "../../utils/hash/password";
import userAdminUpdate from "../../utils/validation/userAdminUpdate";
import {
  useGetAllCompanyListDropdownQuery,
  useGetCompanyIdMutation,
  useGetCompanySubcriptionCmpIdMutation,
} from "../../store/feature/service/companyListApiSlice";
import DatePickerForm from "../../components/molecules/forms/datePickerForm";
import billingInvoice from "../../utils/validation/billingInvoice";
import formatNumberWithCommas from "../../utils/hash/dotSparator";
import dayjs from "dayjs";
import {
  useCreateBillingMutation,
  useGetBillingByIdMutation,
  useUpdateBillingMutation,
} from "../../store/feature/service/billingInvoiceApiSlice";
import EditBilling from "./EditBilling";
import EditBillingInvoice from "../../utils/validation/EditBillingInvoice";
import { getAdminRoles, geteMailUser } from "../../utils/storage";
import BillingDetail from "./BillingDetail";
import EditBillingInvoiceCancel from "../../utils/validation/EditBillingInvoiceCancel";

const BillingInvoiceField = () => {
  //#region INIT

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const [mode, setMode] = useState(FieldMode.create);
  const [isShowPass, setIsShowPass] = useState(false);
  const [onConfirmation, setOnConfirmation] = useState(false);
  const [cpnName, setCpnName] = useState("");
  const [listCmp, setListCmp] = useState([]);
  const [optPrdName, setoptPrdName] = useState([]);
  const [dateDetail, setDateDetail] = useState("");
  const [billUid, setBillUid] = useState("");
  const [buttonPaid, setButtonPaid] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [statusMandatory, setStatusMandatory] = useState(false);
  const [fileAttachment, setFileAttachment] = useState("");
  const { data: roles, isLoading: isLoadingRoles } = useGetAllRolesQuery({});

  const tempRoles: any = roles !== undefined ? roles?.data?.data : [];

  const optRoleGroup = tempRoles.map((item: any) => ({
    label: item.adm_role_name,
    value: item.adm_role_uid,
  }));

  const optStatus = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const { data: listDropdownCmp, isLoading: isLoadingUserAdminDetail } =
    useGetAllCompanyListDropdownQuery({});

  const [
    getCompanyId,
    {
      data: cmpIdPayload,
      isSuccess: cmpIdSuccess,
      isError: cmpIdError,
      error: errorcmpIdMsg,
    },
  ]: any = useGetCompanyIdMutation();

  const [
    getBillingById,
    {
      data: billingByIdPayload,
      isSuccess: billingByIdSuccess,
      isError: billingByIdError,
      error: errorBillingByIdMsg,
    },
  ]: any = useGetBillingByIdMutation();

  const [
    getCompanySubcriptionCmpId,
    {
      data: cmpSubsPayload,
      isLoading: cmpSubsLoading,
      isSuccess: cmpSubsSuccess,
      isError: cmpSubsError,
      error: errorcmpSubsMsg,
    },
  ]: any = useGetCompanySubcriptionCmpIdMutation();

  const [
    createBilling,
    {
      data: billingPayload,
      isLoading: billingLoading,
      isSuccess: billingSuccess,
      isError: billingError,
      error: errorBillingMsg,
    },
  ]: any = useCreateBillingMutation();

  // useUpdateBillingMutation

  const [
    updateBilling,
    {
      data: updateBillingPayload,
      isLoading: updateBillingLoading,
      isSuccess: updateBillingSuccess,
      isError: updateBillingError,
      error: errorupdateBillingMsg,
    },
  ]: any = useUpdateBillingMutation();

  const statusYup = statusMandatory
    ? EditBillingInvoice
    : EditBillingInvoiceCancel;
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    companyName: string;
    companyPhoneNum: string;
    regisNum: string;
    companyEmail: string;
    companyAddress: string;
    productName: string;
    priceBaseOnPlan: string;
    planName: string;
    othersFee: string;
    dueDate: string;
    note: string;
    paymentDate: string;
    attachment: string;
    remarks: string;
    status: string;
    dateIssued: string;
    billingPeriod: string;
    dueDateDetail: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(
      mode == FieldMode.create ? billingInvoice : statusYup
    ),
    defaultValues: {
      companyName: "",
      companyPhoneNum: "",
      regisNum: "",
      companyEmail: "",
      companyAddress: "",
      productName: "",
      priceBaseOnPlan: "",
      planName: "",
      othersFee: "",
      dueDate: "",
      note: "",
      paymentDate: "",
      attachment: "",
      remarks: "",
      status: "",
      dateIssued: "",
      billingPeriod: "",
      dueDateDetail: "",
    },
  });

  useEffect(() => {
    if (pathname.includes("create")) {
      setMode(FieldMode.create);
    } else if (pathname.includes("edit")) {
      getBillingById(idParam.id);
      setMode(FieldMode.edit);
    } else if (pathname.includes("detail")) {
      getBillingById(idParam.id);
      setMode(FieldMode.detail);
    }
  }, []);

  useEffect(() => {
    if (mode !== FieldMode.create) {
      if (idParam.id !== undefined) {
        // getUserAdminDetail({ user_admin_uid: idParam.id });
      }
    }
  }, [mode]);

  useEffect(() => {
    if (listDropdownCmp) {
      setListCmp(
        listDropdownCmp?.data?.map((item: any) => ({
          label: item.company_name,
          value: item.company_uid,
        }))
      );
    }
  }, [listDropdownCmp]);

  useEffect(() => {
    // console.log(
    //   "cmpSubsPayload ===> ",
    //   cmpSubsPayload?.data?.map((item: any) => item.company_subscriptions)
    // );
    const result: any = [];

    if (cmpSubsPayload) {
      cmpSubsPayload?.data?.forEach((user: any) => {
        if (user.company_subscriptions !== null) {
          user.company_subscriptions.forEach((subscription: any) => {
            result.push({
              label: subscription.product_name,
              value: subscription.company_subscription_uid,
              price: subscription.pricing_plan_config_price,
              planName: subscription.pricing_plan_config_name,
            });
          });
        }
      });
    }

    console.log("result here ===> ", result);
    setoptPrdName(result);
  }, [cmpSubsPayload]);

  const onSubmit = (e: any) => {
    // if (mode == FieldMode.create) {
    //   let params = {
    //     adm_role_uid: e.roleGroup,
    //     adm_user_email: e.email,
    //     adm_user_firstname: e.firstName,
    //     adm_user_lastname: e.lastName,
    //     adm_user_mobile: e.mobile,
    //     adm_user_pwd_hash: encrypt(e.password),
    //     adm_user_status: e.status.toLowerCase(),
    //   };
    // createUserAdmin(params);
    // } else if (mode == FieldMode.edit) {
    //   let params = {
    //     adm_role_uid: e.roleGroup,
    //     adm_user_email: e.email,
    //     adm_user_firstname: e.firstName,
    //     adm_user_lastname: e.lastName,
    //     adm_user_mobile: e.mobile,
    //     adm_user_status: e.status.toLowerCase(),
    //   };
    // updateUserAdmin({ body: params, user_admin_uid: idParam.id });
    // }

    // console.log("cmpIdPayload ===> ", cmpIdPayload);
    // console.log("cmpSubsPayload ====> ", cmpSubsPayload);
    if (mode == FieldMode.create) {
      const cpnId = cmpIdPayload?.data;
      setValue("companyPhoneNum", cpnId.cpn_phone_number);
      setValue("regisNum", cpnId.cpn_uid);
      setValue("companyEmail", cpnId.cpn_email);
      setValue("companyAddress", cpnId.cpn_address);
      setOnConfirmation(true);
    } else if (mode == FieldMode.edit) {
      let bodyUpdtBill = {
        adm_user_email: geteMailUser(),
        cpn_bill_attachment_link: fileContent,
        cpn_bill_paid_at:
          e.status === "cancel"
            ? null
            : dayjs(e.paymentDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        cpn_bill_remarks_status: e.remarks,
        cpn_bill_status: e.status,
      };
      console.log("e hehe ==> ", bodyUpdtBill);
      Swal.fire({
        title: "Update Data Billing",
        text: "Are you sure you want to update this billing? Once update, the data can not be update again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2e4054",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then((result) => {
        if (result.isConfirmed) {
          updateBilling({ body: bodyUpdtBill, id: billUid });
        }
      });
    }
  };

  const handleCompanyName = (e: any) => {
    console.log("company name ===> ", e.value);
    getCompanyId({ id: e.value });
    getCompanySubcriptionCmpId({ id: e.value });
    setCpnName(e.label);
  };

  const handlePrdName = (e: any) => {
    console.log("mantap ===> ", e);
    setValue("priceBaseOnPlan", e.price);
    setValue("planName", e.planName);
  };

  const handleCreateConfirm = () => {
    const prdName: any = optPrdName.find(
      (item: any) => item.value === watch("productName")
    );
    const cpnBilling = {
      cpn_bill_company_email: watch("companyEmail"),
      cpn_bill_company_name: cpnName,
      cpn_bill_company_phone: watch("companyPhoneNum"),
      cpn_bill_company_reg_number: watch("regisNum"),
      cpn_bill_due_date: dayjs(watch("dueDate")).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      ),
      cpn_bill_grand_total:
        watch("priceBaseOnPlan") + Number(watch("othersFee")),
      cpn_bill_notes: watch("note"),
      cpn_bill_others_fee: Number(watch("othersFee")),
      cpn_bill_plan_name: watch("planName"),
      cpn_bill_plan_price: watch("priceBaseOnPlan"),
      cpn_bill_product_subscribe: prdName?.label,
      cpn_bill_status: "open",
    };

    Swal.fire({
      title: "Create Billing",
      text: "Are you sure to create this billing? Once created the data can not be edited",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2e4054",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then((result) => {
      if (result.isConfirmed) {
        createBilling(cpnBilling);
      }
    });
  };

  const base64ToFile = (base64String: any, fileName: any) => {
    // Decode the Base64 string
    const binaryString = window.atob(base64String);

    // Function to determine MIME type from binary data
    const getMimeType = (binaryString: any) => {
      const pdfHeader = "%PDF-";
      const jpgHeader = "\xFF\xD8\xFF";
      const pngHeader = "\x89PNG\r\n\x1A\n";

      if (binaryString.startsWith(pdfHeader)) {
        return "application/pdf";
      } else if (binaryString.startsWith(jpgHeader)) {
        return "image/jpeg";
      } else if (binaryString.startsWith(pngHeader)) {
        return "image/png";
      } else {
        return "application/octet-stream"; // Fallback type
      }
    };

    // Determine MIME type
    const mimeType = getMimeType(binaryString);

    // Convert binary string to byte array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: mimeType });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger a click event on the link
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleButtonAttachment = () => {
    base64ToFile(fileAttachment, "payment-information");
  };

  useEffect(() => {
    if (billingByIdPayload) {
      const data = billingByIdPayload?.data;
      console.log("haha ===> ", data);
      setValue("companyName", data.cpn_bill_company_reg_number);
      setValue("companyPhoneNum", data.cpn_bill_company_phone);
      setValue("regisNum", data.cpn_bill_company_reg_number);
      setValue("companyEmail", data.cpn_bill_company_email);
      // setValue("companyAddress", data.cpn_bill_company_reg_number);
      setValue("productName", data.cpn_bill_product_subscribe);
      setValue("priceBaseOnPlan", data.cpn_bill_plan_price);
      setValue("planName", data.cpn_bill_plan_name);
      setValue("othersFee", data.cpn_bill_others_fee);
      setValue("dueDate", dayjs(data.cpn_bill_due_date).format("DD/MM/YYYY"));
      setValue("note", data.cpn_bill_notes);
      setValue(
        "dateIssued",
        dayjs(data.cpn_bill_created_at).format("DD/MM/YYYY")
      );
      setValue(
        "dueDateDetail",
        dayjs(data.cpn_bill_due_date).format("DD/MM/YYYY")
      );
      setOnConfirmation(true);
      setBillUid(data.cpn_bill_uid);
      setDateDetail(data.cpn_bill_statuschange_at);
      setButtonPaid(data.cpn_bill_status);
      if (mode === FieldMode.detail) {
        setValue("paymentDate", data.cpn_bill_paid_at);
        if (data.cpn_bill_attachment_link.length > 0) {
          setValue("attachment", "payment-information");
        } else {
          setValue("attachment", "no file uploaded");
        }
        setFileAttachment(data.cpn_bill_attachment_link);
        setValue("remarks", data.cpn_bill_remarks_status);
        console.log("cmpSubsPayload ===> ", cmpSubsPayload);
      }
      getCompanyId({ id: data.cpn_bill_company_reg_number });
      getCompanySubcriptionCmpId({ id: data.cpn_bill_company_reg_number });
    }
    console.log("cmpSubsPayload ===> ", cmpSubsPayload);
  }, [billingByIdPayload]);

  useEffect(() => {
    if (billingByIdPayload && mode !== FieldMode.create && cmpIdPayload) {
      const cpnData = cmpIdPayload?.data;
      setValue("companyAddress", cpnData.cpn_address);
      console.log("note note ===>", cpnData.cpn_address);
    }
    // if (billingByIdPayload && mode === FieldMode.detail && cmpSubsPayload) {
    //   const cpnSubsData = cmpSubsPayload?.data;
    //   setValue("productName", cpnSubsData.product_name);
    //   console.log('hahaha ===> ', cpnSubsData)
    // }
  }, [cmpIdPayload]);

  useEffect(() => {
    if (billingSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Billing",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Billing",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/billing-invoice");
      });
    }
  }, [billingSuccess]);

  useEffect(() => {
    if (updateBillingSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Billing",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Billing",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        navigate("/billing-invoice");
      });
    }
  }, [updateBillingSuccess]);

  //#endregion

  //#region UI VIEW

  const title = () => {
    var title = "";
    if (mode == FieldMode.create) {
      {
        onConfirmation
          ? (title = "Billing Confirmation")
          : (title = "Create Billing");
      }
    } else if (mode == FieldMode.edit) {
      title = "Update Billing";
    } else if (mode == FieldMode.detail) {
      title = "Detail Billing";
    }
    return (
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {title}
      </Typography>
    );
  };

  const buttons = () => {
    var saveBtn;
    if (mode == FieldMode.create || mode == FieldMode.edit) {
      saveBtn = (
        <Button variant="contained" type="submit">
          {mode === FieldMode.create ? "Next" : "Save"}
        </Button>
      );
    } else {
      saveBtn = null;
    }
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/billing-invoice")}
        >
          Cancel
        </Button>
        {saveBtn}
      </Stack>
    );
  };

  const confirmButton = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="gray"
          onClick={() => setOnConfirmation(false)}
        >
          Back
        </Button>
        <Button variant="contained" onClick={handleCreateConfirm}>
          Create
        </Button>
      </Stack>
    );
  };

  const contents = () => {
    return (
      <>
        <Grid container spacing={2}>
          {onConfirmation && (
            <>
              {mode !== FieldMode.create && (
                <>
                  <Grid item xs={6} md={6} />
                  <Grid
                    item
                    xs={5.8}
                    md={5.8}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    {buttonPaid === "paid" ? (
                      <Button variant="contained" color="success" size="large">
                        <Typography variant="body16semibold">PAID</Typography>
                      </Button>
                    ) : buttonPaid === "cancel" ? (
                      <Button variant="contained" color="error" size="large">
                        CANCEL
                      </Button>
                    ) : (
                      <Button variant="contained" color="gray" size="large">
                        OPEN
                      </Button>
                    )}
                  </Grid>{" "}
                </>
              )}
              <Grid item xs={6} md={6}>
                {mode !== FieldMode.create && (
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    fontFamily="Open Sans"
                    mb={3}
                  >
                    Billing Number <br />
                    {billUid}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  fontFamily="Open Sans"
                  mb={3}
                >
                  Total Bill <br />
                  RP.{" "}
                  {formatNumberWithCommas(
                    watch("priceBaseOnPlan") + Number(watch("othersFee"))
                  )}
                </Typography>
              </Grid>
            </>
          )}
          <BillingDetail
            mode={mode}
            errors={errors}
            control={control}
            dateDetail
          />
          <Grid item xs={12} md={12}>
            <Typography
              variant="h5"
              fontWeight="bold"
              fontFamily="Open Sans"
              mb={3}
            >
              Company Information
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <SelectFormm
              name="companyName"
              label="Company Name"
              defaultValue={0}
              options={listCmp}
              placeholder="none"
              isLoading={false}
              control={control}
              errors={errors}
              isSearch
              required
              disabled={mode == FieldMode.detail || onConfirmation}
              onChangeForm={handleCompanyName}
            />
            {onConfirmation && (
              <>
                <InputForm
                  name="companyPhoneNum"
                  label="Company Phone Number"
                  control={control}
                  errors={errors}
                  maxLength={100}
                  isLoading={false}
                  type="number"
                  placeholder="Type Company Phone number..."
                  disabled
                  showStartAdornment
                  startAdornment={"+62"}
                  sxEndAdornment={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    width: "4rem",
                  }}
                />
                <InputForm
                  name="regisNum"
                  label="Registration Number"
                  errors={errors}
                  control={control}
                  maxLength={100}
                  isLoading={false}
                  placeholder="Type Registration Number..."
                  disabled
                  required
                />
              </>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            {onConfirmation && (
              <>
                <InputForm
                  name="companyEmail"
                  label="Company Email"
                  errors={errors}
                  control={control}
                  maxLength={100}
                  isLoading={false}
                  placeholder="Type Company Email..."
                  disabled
                  required
                />
                <InputForm
                  name="companyAddress"
                  label="Company Address"
                  errors={errors}
                  control={control}
                  maxLength={100}
                  isLoading={false}
                  placeholder="Type Company Address..."
                  disabled
                  required
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h5"
              fontWeight="bold"
              fontFamily="Open Sans"
              mb={3}
            >
              Product Information
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            {mode !== FieldMode.create ? (
              <InputForm
                name="productName"
                label="Product Name"
                errors={errors}
                control={control}
                maxLength={100}
                isLoading={false}
                placeholder="Type Company Email..."
                disabled
                required
              />
            ) : (
              <SelectFormm
                name="productName"
                label="Product Name"
                defaultValue={0}
                options={optPrdName}
                placeholder="none"
                isLoading={false}
                control={control}
                errors={errors}
                isSearch
                required
                disabled={onConfirmation}
                onChangeForm={handlePrdName}
              />
            )}
            {mode !== FieldMode.detail && mode !== FieldMode.create && (
              <InputForm
                name="priceBaseOnPlan"
                label="Price based on Plan"
                control={control}
                errors={errors}
                maxLength={100}
                isLoading={false}
                type="number"
                // placeholder="+62"
                disabled
                showStartAdornment
                startAdornment={"Rp"}
                placeholder="Type Price based on plan.."
                required
                sxEndAdornment={{
                  backgroundColor: "inherit",
                  border: "none",
                  cursor: "pointer",
                  width: "4rem",
                }}
              />
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            {onConfirmation && (
              <InputForm
                name="planName"
                label="Plan Name"
                errors={errors}
                control={control}
                maxLength={100}
                isLoading={false}
                placeholder="Type Company Email..."
                disabled
                required
              />
            )}
            {mode === FieldMode.create && (
              <InputForm
                name="priceBaseOnPlan"
                label="Price based on Plan"
                control={control}
                errors={errors}
                maxLength={100}
                isLoading={false}
                type="number"
                // placeholder="+62"
                disabled
                showStartAdornment
                startAdornment={"Rp"}
                placeholder="Type Price based on Plan.."
                required
                sxEndAdornment={{
                  backgroundColor: "inherit",
                  border: "none",
                  cursor: "pointer",
                  width: "4rem",
                }}
              />
            )}
          </Grid>
          {mode !== FieldMode.detail && (
            <>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="Open Sans"
                  mb={3}
                >
                  Billing Information
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <InputForm
                  name="othersFee"
                  label="Others Fee"
                  control={control}
                  errors={errors}
                  maxLength={100}
                  isLoading={false}
                  type="number"
                  placeholder="Type Others Fee.."
                  showStartAdornment
                  startAdornment={"Rp"}
                  sxEndAdornment={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    width: "4rem",
                  }}
                  required
                  showPrice
                  disabled={onConfirmation}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                {mode === FieldMode.create ? (
                  <DatePickerForm
                    name="dueDate"
                    label="Due Date"
                    defaultValue={null}
                    control={control}
                    errors={errors}
                    placeholder="Due Date"
                    isLoading={false}
                    required
                    disabled={onConfirmation}
                  />
                ) : (
                  <InputForm
                    name="dueDate"
                    label="Due Date"
                    errors={errors}
                    control={control}
                    maxLength={100}
                    isLoading={false}
                    placeholder="Type Company Email..."
                    disabled
                    required
                  />
                )}
                <TextAreaForm
                  name="note"
                  label="Note"
                  defaultValue={""}
                  control={control}
                  errors={errors}
                  placeholder="Type Note..."
                  disabled={onConfirmation}
                />
              </Grid>
            </>
          )}
          {(mode !== FieldMode.create && buttonPaid !== "open") ||
          mode === FieldMode.edit ||
          (mode === FieldMode.detail && buttonPaid !== "open") ? (
            <EditBilling
              mode={mode}
              control={control}
              errors={errors}
              fileContent={fileContent}
              setFileContent={setFileContent}
              dateDetail={dateDetail}
              buttonPaid={buttonPaid}
              setStatusMandatory={setStatusMandatory}
              statusMandatory={statusMandatory}
              handleButtonAttachment={handleButtonAttachment}
              fileAttachment={fileAttachment}
            />
          ) : null}
        </Grid>
        <Box sx={{ height: "30px" }} />
      </>
    );
  };

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
      isLoading={isLoadingRoles}
      isLoadingEvent={false}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        {title()}
        {contents()}
        {onConfirmation && mode === FieldMode.create
          ? confirmButton()
          : buttons()}
      </Box>
    </DetailDataSkeleton>
  );

  //#endregion
};

export default BillingInvoiceField;

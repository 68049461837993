import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import { useGetAllProductListQuery } from "../../store/feature/service/productListApiSlice";
import CompanyListSearch from "../../components/molecules/companyList/CompanyListSearch";
import CompanyListTable from "../../components/molecules/companyList/CompanyListTable";
import { useGetAllCompanyListQuery } from "../../store/feature/service/companyListApiSlice";

const CompanyList = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  // const { data, isLoading } = useGetAllProductListQuery(params);
  const { data, isLoading } = useGetAllCompanyListQuery(params)
  console.log('data === > ', data)
  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Company List
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        </Box>
        <CompanyListSearch params={params} setParams={setParams} />
        <CompanyListTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default CompanyList;

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Modal,
  IconButton,
  Chip,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import productList from "../../utils/validation/productList";
import { FieldMode } from "../../constants/enum/FieldMode";
import React from "react";
import { Close } from "@mui/icons-material";
import FileForm from "../../components/molecules/forms/fileForm";
import Swal from "sweetalert2";
import addDashboard from "../../utils/validation/addDashboard";
import {
  useCreateProductListMutation,
  useUpdateProductListMutation,
  useGetAllCategoriesQuery,
  useGetProductByIdMutation,
  useGetAllDashbordLinkMutation,
  useGetDashbordLinkByIdMutation,
  useCreateDashboardLinkMutation,
  useUpdateDashboardLinkMutation,
  useDeleteDashboardLinkMutation,
} from "../../store/feature/service/productListApiSlice";
import CompanyPanel from "../../components/molecules/companyList/CompanyPanel";
import Product from "../../components/molecules/companyList/Product";
import DocumentNeeded from "../../components/molecules/companyList/DocumentNeeded";
import companyList from "../../utils/validation/companyList";
import {
  useCreateCompanyListMutation,
  useGetCompanyIdMutation,
  useUpdateCompanyListMutation,
} from "../../store/feature/service/companyListApiSlice";

const CompanyListField = () => {
  //#region INIT

  const navigate = useNavigate();
  const location = useLocation();
  const idParam = useParams();
  const [uid, setUid] = React.useState<string>();
  const { pathname } = location;
  const [mode, setMode] = useState(FieldMode.create);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [isEditDashboard, setIsEditDashboard] = React.useState(false);
  const [fileContentIcon, setFileContentIcon] = useState<String>();
  const [fileContentBanner, setFileContentBanner] = useState<String>();
  const { data: categories, isLoading: isLoadingCategory } =
    useGetAllCategoriesQuery({});

  const [
    getProductDetail,
    { data: productDetail, isLoading: isLoadingProduct },
  ]: any = useGetProductByIdMutation();

  const [
    createCompany,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      error: errorCreateMsg,
    },
  ]: any = useCreateCompanyListMutation();

  const [
    updateCompany,
    {
      data: updateCompanyPayload,
      isLoading: updateCompanyLoading,
      isSuccess: updateCompanySuccess,
      isError: updateCompanyError,
      error: errorUpdateCompanyMsg,
    },
  ]: any = useUpdateCompanyListMutation();

  const [
    updateProduct,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
      error: errorUpdateMsg,
    },
  ]: any = useUpdateProductListMutation();

  const [
    getAllDashboardLink,
    { data: dashboardLink, isLoading: isLoadingDashboardLink },
  ]: any = useGetAllDashbordLinkMutation();

  const [
    getDashboardLinkDetail,
    { data: dashboardLinkDetail, isLoading: isLoadingDashboardLinkDetail },
  ]: any = useGetDashbordLinkByIdMutation();

  const [
    createDashboardLink,
    {
      data: createDashboardLinkPayload,
      isLoading: createDashboardLinkLoading,
      isSuccess: createDashboardLinkSuccess,
      isError: createDashboardLinkError,
      error: errorCreateDashboardLinkMsg,
    },
  ]: any = useCreateDashboardLinkMutation();

  const [
    updateDashboardLink,
    {
      data: updateDashboardLinkPayload,
      isLoading: updateDashboardLinkLoading,
      isSuccess: updateDashboardLinkSuccess,
      isError: updateDashboardLinkError,
      error: errorUpdateDashboardLinkMsg,
    },
  ]: any = useUpdateDashboardLinkMutation();

  const [
    deleteDashboardLink,
    {
      data: deleteDashboardLinkPayload,
      isLoading: deleteDashboardLinkLoading,
      isSuccess: deleteDashboardLinkSuccess,
      isError: deleteDashboardLinkError,
    },
  ] = useDeleteDashboardLinkMutation();

  const [getCompanyId, { data: compIdData, isLoading: successCompId }] =
    useGetCompanyIdMutation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue: valueSet,
  } = useForm<{
    uid: string;
    companyName: string;
    mobile: string;
    businessField: string;
    vatNumber: string;
    companyStatus: string;
    companyEmail: string;
    companyAddress: string;
    note: string;
    picName: string;
    mobilePic: string;
    picEmail: string;
    picRole: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(companyList),
    defaultValues: {
      uid: "",
      companyName: "",
      mobile: "",
      businessField: "",
      vatNumber: "",
      companyStatus: "",
      companyEmail: "",
      companyAddress: "",
      note: "",
      picName: "",
      mobilePic: "",
      picEmail: "",
      picRole: "",
    },
  });

  const {
    control: controlDashboard,
    reset: resetDashboard,
    handleSubmit: handleDashboard,
    formState: { errors: errorDasboard },
  } = useForm<{
    // dashboardStatus: string;
    dashboardUid: string;
    dashboardName: string;
    dashboardUrl: string;
    number: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(addDashboard),
    defaultValues: {
      // dashboardStatus: "",
      dashboardUid: "",
      dashboardName: "",
      dashboardUrl: "",
      number: 0,
    },
  });

  const tempCategories: any = categories !== undefined ? categories?.data : [];

  const optSelect = tempCategories.map((item: any) => ({
    label: item.product_category_name,
    value: item.product_category_uid,
  }));

  const optStatus = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  useEffect(() => {
    if (pathname.includes("create")) {
      setMode(FieldMode.create);
    } else if (pathname.includes("edit")) {
      setMode(FieldMode.edit);
    } else if (pathname.includes("detail")) {
      setMode(FieldMode.detail);
    }
  }, []);

  useEffect(() => {
    if (dashboardLink != undefined) {
      setRowData(
        dashboardLink.data !== null
          ? dashboardLink.data.map((item: any, index: number) => ({
              id: item.prd_dashboard_link_uid,
              number: index + 1,
              status: "inactive",
              dashboardName: item.prd_dashboard_link_name,
              dashboardUrl: item.prd_dashboard_link_url,
            }))
          : []
      );
    }
  }, [dashboardLink]);

  useEffect(() => {
    if (dashboardLinkDetail != undefined) {
      if (dashboardLinkDetail.data != null) {
        resetDashboard({
          dashboardName: dashboardLinkDetail.data.prd_dashboard_link_name,
          dashboardUrl: dashboardLinkDetail.data.prd_dashboard_link_url,
          dashboardUid: dashboardLinkDetail.data.prd_dashboard_link_uid,
          // dashboardStatus: params.row.status,
        });
      }
    }
  }, [dashboardLinkDetail]);

  useEffect(() => {
    getAllDashboardLink({ product_uid: uid });
  }, [
    createDashboardLinkSuccess,
    createDashboardLinkError,
    updateDashboardLinkSuccess,
    updateDashboardLinkError,
    deleteDashboardLinkSuccess,
    deleteDashboardLinkError,
  ]);

  useEffect(() => setUid(idParam.id), [idParam]);

  const onSubmit = (e: any) => {
    if (mode == FieldMode.create) {
      let bodyCreate = {
        company_address: e.companyAddress,
        company_description: e.note,
        company_email: e.companyEmail,
        company_industry: e.businessField,
        company_name: e.companyName,
        company_phone_number: e.mobile,
        company_pic1_email: e.picEmail,
        company_pic1_name: e.picName,
        company_pic1_phone: e.mobilePic,
        company_pic1_position: e.picRole,
        company_status: e.companyStatus.toLowerCase(),
        company_vatnbr: e.vatNumber,
      };
      createCompany(bodyCreate);
      //   let params = {
      //     product_api_documentation_link: e.productDocumentationLink,
      //     product_category_uid: e.productCategory,
      //     product_description: e.productDescription,
      //     product_icon: fileContentIcon?.replace(
      //       /^data:image\/(png|jpeg|jpg);base64,/,
      //       ""
      //     ),
      //     product_image_banner: fileContentBanner?.replace(
      //       /^data:image\/(png|jpeg|jpg);base64,/,
      //       ""
      //     ),
      //     product_name: e.companyName,
      //     product_code: e.mobile,
      //     product_key_optibusinessField
      //     product_status: vatNumberLowerCase(),
      //   };
      //   createProduct(params);
    } else if (mode == FieldMode.edit) {
      //   let params = {
      //     product_api_documentation_link: e.productDocumentationLink,
      //     product_category_uid: e.productCategory,
      //     product_description: e.productDescription,
      //     product_icon: fileContentIcon?.replace(
      //       /^data:image\/(png|jpeg|jpg);base64,/,
      //       ""
      //     ),
      //     product_image_banner: fileContentBanner?.replace(
      //       /^data:image\/(png|jpeg|jpg);base64,/,
      //       ""
      //     ),
      //     product_name: e.companyName,
      //     product_code: e.mobile,
      //     product_key_optibusinessField
      //     product_status: vatNumberLowerCase(),
      //   };
      //   updateProduct({ body: params, id: e.uid });
      let bodyUpdate = {
        company_address: e.companyAddress,
        company_description: e.note,
        company_email: e.companyEmail,
        company_industry: e.businessField,
        company_name: e.companyName,
        company_phone_number: e.mobile,
        company_pic1_email: e.picEmail,
        company_pic1_name: e.picName,
        company_pic1_phone: e.mobilePic,
        company_pic1_position: e.picRole,
        company_status: e.companyStatus.toLowerCase(),
        company_vatnbr: e.vatNumber,
      };
      updateCompany({ id: idParam.id, body: bodyUpdate });
    }
  };

  useEffect(() => {
    if (createPayload != undefined) {
      setUid(createPayload.data.product_uid);
    }
  }, [createPayload]);

  useEffect(() => {
    if (createSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Company",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Company",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => setValue(1));
    }
  }, [createSuccess]);

  useEffect(() => {
    if (createError) {
      Swal.fire({
        icon: "error",
        title: "Error Create Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorCreateMsg?.data.msg,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createError]);

  useEffect(() => {
    if (updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {});
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updateError]);

  useEffect(() => {
    if (mode !== FieldMode.create) {
      getCompanyId({ id: idParam.id });
    }
  }, []);

  // useEffect(() => {
  //   if (createDashboardLinkError) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error Create Dashboard Link",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: errorCreateMsg?.data.msg,
  //       didOpen: () => Swal.getConfirmButton()?.focus(),
  //     });
  //   }
  // }, [createDashboardLinkError]);

  useEffect(() => {
    if (updateCompanySuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Company",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Company",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {});
    }
  }, [updateCompanySuccess]);

  // useEffect(() => {
  //   if (updateDashboardLinkError) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error Update Dashboard Link",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Error Update Product",
  //       didOpen: () => Swal.getConfirmButton()?.focus(),
  //     });
  //   }
  // }, [updateDashboardLinkError]);

  // useEffect(() => {
  //   if (deleteDashboardLinkSuccess) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Delete Dashboard Link",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Success Delete Dashboard Link",
  //       didOpen: () => Swal.getConfirmButton()?.focus(),
  //     }).then(() => {});
  //   }
  // }, [deleteDashboardLinkSuccess]);

  // useEffect(() => {
  //   if (deleteDashboardLinkError) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error Delete Dashboard Link",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Error Delete Product",
  //       didOpen: () => Swal.getConfirmButton()?.focus(),
  //     });
  //   }
  // }, [deleteDashboardLinkError]);
  const onAdd = (e: any) => {
    if (!isEditDashboard) {
      let params = {
        product_dashboard_link_name: e.dashboardName,
        product_dashboard_link_url: e.dashboardUrl,
        product_uid: uid,
      };
      createDashboardLink(params);
    } else {
      let params = {
        product_dashboard_link_name: e.dashboardName,
        product_dashboard_link_url: e.dashboardUrl,
        product_uid: uid,
      };
      updateDashboardLink({
        body: params,
        product_dashboard_link_uid: e.dashboardUid,
      });
    }
    closeModal();
  };

  const openModal = () => {
    if (uid != undefined) {
      setOpen(true);
      setIsEditDashboard(false);
      resetDashboard({
        dashboardName: "",
        // dashboardStatus: "",
        dashboardUrl: "",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Can not add url dashoard",
        text: "Please create product first!",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#2e4054",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        setValue(0);
      });
    }
  };

  const closeModal = () => {
    setOpen(false);
    resetDashboard({
      dashboardName: "",
      dashboardUrl: "",
      // dashboardStatus: "",
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFileChangeIcon = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      setFileContentIcon(
        `${reader.result
          ?.toString()
          .replace(/^data:image\/(png|jpeg|jpg);base64,/, "")}`
      );
    };
  };

  const handleFileChangeBanner = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      setFileContentBanner(
        `${reader.result
          ?.toString()
          .replace(/^data:image\/(png|jpeg|jpg);base64,/, "")}`
      );
    };
  };

  //#endregion

  //#region UI VIEW

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const title = () => {
    switch (mode) {
      case FieldMode.create: {
        return "Create Company";
      }
      case FieldMode.edit: {
        return "Update Company";
      }
      case FieldMode.detail: {
        return "Detail Company";
      }
    }
  };

  const buttons = () => {
    var saveBtn;
    if (mode == FieldMode.create || mode == FieldMode.edit) {
      saveBtn =
        value == 0 ? (
          <>
            <Button
              variant="contained"
              color="gray"
              onClick={() => navigate("/company-list")}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </>
        ) : null;
    } else {
      saveBtn = null;
    }

    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {saveBtn}
      </Stack>
    );
  };

  const tabPanel = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="basic tabs example"
            sx={{
              fontWeight: "bold",
            }}
          >
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="Product" {...a11yProps(1)} />
            <Tab label="Document Needed" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CompanyPanel
            errors={errors}
            control={control}
            mode={mode}
            fileContentIcon={fileContentIcon}
            reset={reset}
            setValue={valueSet}
            setFileContentIcon={setFileContentIcon}
            getValues={getValues}
            handleFileChangeIcon={handleFileChangeIcon}
            fileContentBanner={fileContentBanner}
            handleFileChangeBanner={handleFileChangeBanner}
            setFileContentBanner={setFileContentBanner}
            optSelect={optSelect}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {/* {dashboardPanel()} */}
          <Product setValue={setValue} mode={mode} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Typography>
            <DocumentNeeded setValue={setValue} mode={mode} compIdData={compIdData} />
          </Typography>
        </CustomTabPanel>
      </Box>
    );
  };

  const addDashboardModal = (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "30px",
          width: "50%",
        }}
        component="form"
        onSubmit={handleDashboard(onAdd)}
      >
        <Box sx={{ display: "flex" }} mb={1}>
          <Typography variant="h4" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
            Add Dashboard
          </Typography>
          <IconButton onClick={() => closeModal()}>
            <Close
              sx={{
                height: "20px",
                width: "20px",
                padding: "0px",
                alignSelf: "center",
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ height: "20px" }} />
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <InputForm
              name="dashboardName"
              label="Dashboard Name"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="dashboard name.."
            />
            {/* <RadioForm
              defaultValue={"ACTIVE"}
              name="dashboardStatus"
              label="Status"
              control={controlDashboard}
              errors={errorDasboard}
              required
              isLoading={false}
              options={optStatus}
          /> */}
          </Grid>
          <Grid item xs={6} md={6}>
            <InputForm
              name="dashboardUrl"
              label="Dashboard URL"
              control={controlDashboard}
              errors={errorDasboard}
              required
              maxLength={100}
              isLoading={false}
              placeholder="dashboard url.."
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{ marginLeft: "auto", marginRight: "8px" }}
            variant="contained"
            color="gray"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDashboard(async (e: any) => onAdd(e))}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
      isLoading={isLoadingCategory || isLoadingProduct}
      isLoadingEvent={
        createLoading ||
        updateLoading ||
        createDashboardLinkLoading ||
        updateDashboardLinkLoading ||
        deleteDashboardLinkLoading ||
        isLoadingDashboardLink
      }
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {title()}
        </Typography>
        {tabPanel()}
        {buttons()}
      </Box>
    </DetailDataSkeleton>
  );

  //#endregion
};

export default CompanyListField;

import { LOCAL_STORAGE_KEY  } from '../constants'

const { TOKEN, ADMIN_ROLE, EMAIL_USER } = LOCAL_STORAGE_KEY;

export function setToken(token: string) {
  return localStorage.setItem(TOKEN, token);
}

export function setAdminRoles(role: any) {
  return localStorage.setItem(ADMIN_ROLE, role);
}

export function setEmailUser(role: any) {
  return localStorage.setItem(EMAIL_USER, role);
}

export function getToken() {
  return localStorage.getItem(TOKEN) || "";
}

export function getAdminRoles() {
  return localStorage.getItem(ADMIN_ROLE) || "";
}

export function geteMailUser() {
  return localStorage.getItem(EMAIL_USER) || "";
}

export function clearStorage() {
  localStorage.removeItem(TOKEN);
}
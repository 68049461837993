import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteProductListMutation } from "../../../store/feature/service/productListApiSlice";
import { useEffect } from "react";

const CompanyListTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const navigate = useNavigate();
  const [
    deleteProductList,
    {
      data: deletePayload,
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      error: errorDeleteData
    },
  ] = useDeleteProductListMutation();

  if (deleteSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Delete Product",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Delete Product",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  useEffect(() => {
    if (deleteError) {
      const errorDelete: any = errorDeleteData
      Swal.fire({
        icon: "error",
        title: "Error Delete Product",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorDelete?.error_details?.detail,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [deleteError]);

  const customCellRender = (params: GridCellParams) => {
    const onDetail = (e: any) => {
      navigate(`/company-list/detail/${params.id}`);
    };

    const onEdit = (e: any) => {
      navigate(`/company-list/edit/${params.id}`);
    };

    const onDelete = (e: any) => {
      Swal.fire({
        title: "Delete Product",
        text: "Are you sure want to delete product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2e4054",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProductList({ id: params.id });
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
          <Button variant="contained" onClick={onEdit}>
            Edit
          </Button>
          {/* <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button> */}
        </Stack>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const tempData: any =
    data?.data?.data !== undefined && data?.data?.data !== null
      ? data?.data?.data
      : [];

  const rows = tempData.map((row: any) => ({
    id: row.cpn_uid,
    companyName: row.cpn_name,
    registrationNumber: row.cpn_uid,
    status: row.cpn_status,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default CompanyListTable;

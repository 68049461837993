import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms";
import { useForm } from "react-hook-form";
import { FieldMode } from "../../constants/enum/FieldMode";
import DatePickerForm from "../../components/molecules/forms/datePickerForm";
import { geteMailUser } from "../../utils/storage";
import dayjs from "dayjs";

const EditBilling = ({
  mode,
  control,
  errors,
  fileContent,
  setFileContent,
  dateDetail,
  buttonPaid,
  setStatusMandatory,
  statusMandatory,
  handleButtonAttachment,
  fileAttachment
}: any) => {
  const handleFileChange = (file: any) => {
    console.log("file A ===> ", file);
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      console.log("===> ", event.target.result.split(",")[1]);
      setFileContent(event.target.result.split(",")[1]);
    };
    fileReader.readAsDataURL(file[0]);
  };
  const listCmp = [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Cancel",
      value: "cancel",
    },
  ];
  const handleStatus = (e: any) => {
    console.log("status ===> ", e);
    setStatusMandatory(e.value === "paid");
  };
  const attachmentWide = mode === FieldMode.detail ? 4 : 6;
  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography
          variant="h5"
          fontWeight="bold"
          fontFamily="Open Sans"
          mb={3}
        >
          Payment Information
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <DatePickerForm
          name="paymentDate"
          label="Payment Date"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Type Payment Date..."
          isLoading={false}
          required={statusMandatory}
          disabled={mode == FieldMode.detail}
        />
      </Grid>
      <Grid item xs={attachmentWide} md={attachmentWide}>
        <InputForm
          name="attachment"
          label="Attachment"
          errors={errors}
          control={control}
          required={statusMandatory}
          maxLength={100}
          isLoading={false}
          placeholder="Plese Select File..."
          type={mode === FieldMode.detail ? "string" : "file"}
          disabled={mode === FieldMode.detail}
          onChange={handleFileChange}
        />
      </Grid>
      {mode === FieldMode.detail &&  (
        <Grid item xs={2} md={2} alignItems={"center"} display={"flex"}>
          <Button variant="contained" onClick={handleButtonAttachment} disabled={fileAttachment.length === 0 }>
            Download
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextAreaForm
          name="remarks"
          label="Remarks"
          errors={errors}
          control={control}
          maxLength={100}
          isLoading={false}
          placeholder="Type Remarks Here..."
          disabled={mode == FieldMode.detail}
          required={statusMandatory}
          defaultValue={1}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        {mode !== FieldMode.detail ? (
          <SelectFormm
            name="status"
            label="Status"
            defaultValue={0}
            options={listCmp}
            placeholder="none"
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            required
            disabled={mode == FieldMode.detail}
            onChangeForm={handleStatus}
          />
        ) : (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              fontFamily="Open Sans"
              mb={3}
            >
              Update By
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontFamily="Open Sans"
              mb={3}
            >
              Admin Autentika {geteMailUser()} <br />
              on {dayjs(dateDetail).format("DD/MM/YYYY")}
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default EditBilling;

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm, TextAreaForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { FieldMode } from "../../constants/enum/FieldMode";
import { Close, Delete, Edit } from "@mui/icons-material";
import React from "react";
import addPricing from "../../utils/validation/addPricing";
import Swal from "sweetalert2";
import { formatCurrency } from "../../utils/stringUtils";
import pricingConfig from "../../utils/validation/pricingConfig";
import { useGetProductNameUidQuery } from "../../store/feature/service/productListApiSlice";
import {
  useCreatePlanConfigMutation,
  useCreatePricingSchemaMutation,
  useDeletePlanConfigMutation,
  useGetPlanConfigByIdMutation,
  useGetPlanConfigBySchemaIdMutation,
  useGetPricingSchemaByIdMutation,
  useUpdatePlanConfigMutation,
  useUpdatePricingSchemaMutation
} from "../../store/feature/service/pricingConfigApiSlice";

const PricingConfigField = () => {

  //#region INIT

  const navigate = useNavigate();
  const location = useLocation();
  const idParam = useParams();
  const [uid, setUid] = useState<String>();
  const { pathname } = location;
  const [mode, setMode] = useState(FieldMode.create);
  const [pricings, setPricings] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [isEditPricing, setIsEditPricing] = React.useState(false);
  const { data: products, isLoading: isLoadingProduct } = useGetProductNameUidQuery({});

  const [
    getPricingSchema,
    {
      data: detailPricingSchema,
      isLoading: isLoadingPricingSchema
    },
  ]: any = useGetPricingSchemaByIdMutation();

  const [
    createPricingSchema,
    {
      data: createPayload,
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      error: errorCreateMsg,
    },
  ]: any = useCreatePricingSchemaMutation();

  const [
    updatePricingSchema,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
      error: errorUpdateMsg,
    },
  ]: any = useUpdatePricingSchemaMutation();

  const [
    getPlanConfigSchema,
    {
      data: detailPlanConfigSchema,
      isLoading: isLoadingPlanConfigSchema
    },
  ]: any = useGetPlanConfigBySchemaIdMutation();

  const [
    getPlanConfigDetail,
    {
      data: detailPlanConfig,
      isLoading: isLoadingPlanConfigDetail
    },
  ]: any = useGetPlanConfigByIdMutation();
  
  const [
    createPlanConfig,
    {
      data: createPlanConfigPayload,
      isLoading: createPlanConfigLoading,
      isSuccess: createPlanConfigSuccess,
      isError: createPlanConfigError,
      error: errorCreatePlanConfigMsg,
    },
  ]: any = useCreatePlanConfigMutation();

  const [
    updatePlanConfig,
    {
      data: updatePlanConfigPayload,
      isLoading: updatePlanConfigLoading,
      isSuccess: updatePlanConfigSuccess,
      isError: updatePlanConfigError,
      error: errorUpdatePlanConfigMsg,
    },
  ]: any = useUpdatePlanConfigMutation();

  const [
    deletePlanConfig,
    {
      data: deletePlanConfigPayload,
      isLoading : deletePlanConfigLoading,
      isSuccess: deletePlanConfigSuccess,
      isError: deletePlanConfigError,
    }
  ] = useDeletePlanConfigMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    productName: string;
    paymentType: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(pricingConfig),
    defaultValues: {
      productName: "",
      paymentType: "",
    },
  });

  const {
    control: controlPricing,
    reset: resetPricing,
    handleSubmit: handlePricing,
    formState: { errors: errorPricing } 
  } = useForm<{
    planUid: string;
    planName: string;
    price: string;
    planCode: string;
    planDescription: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(addPricing),
    defaultValues: {
      planUid: "",
      planName: "",
      price: "",
      planCode: "",
      planDescription: "",
    },
  });

  const tempProducts: any = products !== undefined ? products : []

  const optProduct = tempProducts.map((item: any) => (
    {
      label: item.prd_name,
      value: item.prd_uid,
    }
  ))

  const optPayment = [
    {
      label: "Post Paid",
      value: "post"
    },
    {
      label: "Pre Paid",
      value: "pre"
    }
  ];

  useEffect(() => {
    if(pathname.includes("create")) {
      setMode(FieldMode.create);
    } else if (pathname.includes("edit")) {
      setMode(FieldMode.edit);
    } else if (pathname.includes("detail")) {
      setMode(FieldMode.detail);
    }
  }, []);
  
  useEffect(() => {
    if(mode !== FieldMode.create) {
      if (idParam.id !== undefined) {
        getPricingSchema({ pricing_schema_uid: idParam.id})
        getPlanConfigSchema({ pricing_schema_uid: idParam.id })
      }
    }
  }, [mode]);

  useEffect(() => {
    if(detailPricingSchema != undefined) {
      reset({
        uid: detailPricingSchema?.data?.pricing_schema_uid,
        productName: detailPricingSchema?.data?.product_uid,
        paymentType: detailPricingSchema?.data?.pricing_schema_payment_type,
      })
    }
  }, [detailPricingSchema]);

  useEffect(() => {
    if(detailPlanConfigSchema != undefined) {
      if(detailPlanConfigSchema?.data != null) {
        setPricings(detailPlanConfigSchema?.data.map((item: any, index: number) => {
          return {
              planName: item.prc_plan_config_name,
              price: item.prc_plan_config_price,
              planCode: item.prc_plan_config_code,
              planDescription: item.prc_plan_config_description,
              planUid: item.prc_plan_config_uid
            }
          }
        ))
      } else {
        setPricings([])
      }
    }
  }, [detailPlanConfigSchema]);

  useEffect(() => {
    if(detailPlanConfig != undefined) {
      if(detailPlanConfig?.data != null) {
         resetPricing({
          planName: detailPlanConfig?.data.prc_plan_config_name,
          price: detailPlanConfig?.data.prc_plan_config_price,
          planCode: detailPlanConfig?.data.prc_plan_config_code,
          planDescription: detailPlanConfig?.data.prc_plan_config_description,
          planUid: detailPlanConfig?.data.prc_plan_config_uid
        })
      }
    }
  }, [detailPlanConfig]);

  useEffect(() => {
    if(checkUid()) {
      getPlanConfigSchema({ pricing_schema_uid: uid })
    }
  }, [
    createPlanConfigSuccess,
    createPlanConfigError,
    updatePlanConfigSuccess,
    updatePlanConfigError,
    deletePlanConfigSuccess,
     deletePlanConfigError
  ])

  useEffect(() => setUid(idParam.id), [idParam]);

  useEffect(() => {
    if(createPayload != undefined) {
      setUid(createPayload.data.pricing_schema_uid)
    }
  }, [createPayload])

  useEffect(() => {
    if(createSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Pricing Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Pricing Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [createSuccess])

  useEffect(() => {
    if(createError) {
      console.log('errorCreateMsg ===> ', errorCreateMsg?.data?.error_details?.detail)
      Swal.fire({
        icon: "error",
        title: "Error Create Pricing Schema",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorCreateMsg?.data?.error_details?.detail,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createError])

  useEffect(() => {
    if(updateSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Pricing Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Pricing Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [updateSuccess])

  useEffect(() => {
    if(updateError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Pricing Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Pricing Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updateError])

  useEffect(() => {
    if(createPlanConfigSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Create Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Plan Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [createPlanConfigSuccess])

  useEffect(() => {
    if(createPlanConfigError) {
      Swal.fire({
        icon: "error",
        title: "Error Create Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: errorCreateMsg?.data.msg,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [createPlanConfigError])

  useEffect(() => {
    if(updatePlanConfigSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Update Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Plan Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [updatePlanConfigSuccess])

  useEffect(() => {
    if(updatePlanConfigError) {
      Swal.fire({
        icon: "error",
        title: "Error Update Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [updatePlanConfigError])

  useEffect(() => {
    if(deletePlanConfigSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Delete Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Plan Config",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        
      });
    }
  }, [deletePlanConfigSuccess])

  useEffect(() => {
    if(deletePlanConfigError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete Plan Config",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Product",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [deletePlanConfigError])


  const onSubmit = (e: any) => {
    if(mode == FieldMode.create) {
      if(checkUid()) {
        navigate("/pricing-config")
      } else {
        let params = {
          pricing_schema_payment_due_date: 0,
          pricing_schema_status: "active",
          pricing_schema_payment_type: e.paymentType,
          product_uid: e.productName
        }
        createPricingSchema(params);
      }
    } else if(mode == FieldMode.edit) {
      let params = {
        pricing_schema_payment_due_date: 0,
        pricing_schema_status: "active",
        pricing_schema_payment_type: e.paymentType,
        product_uid: e.productName
      }
      updatePricingSchema({ body: params, pricing_schema_uid: e.uid });
    }
  };

  const onAdd = (e: any) => {
    if(!isEditPricing) {
      let params = {
        pricing_plan_config_code: e.planCode,
        pricing_plan_config_description: e.planDescription,
        pricing_plan_config_name: e.planName,
        pricing_plan_config_price: parseInt(e.price),
        pricing_schema_uid: uid
      }
      createPlanConfig(params)
    } else {
      let params = {
        pricing_plan_config_code: e.planCode,
        pricing_plan_config_description: e.planDescription,
        pricing_plan_config_name: e.planName,
        pricing_plan_config_price: parseInt(e.price),
        pricing_schema_uid: uid
      }
      updatePlanConfig({ body: params, pricing_plan_config_uid: e.planUid })
    }
    setOpen(false)
  };

  const onEdit = (e: any) => {
    setOpen(true)
    setIsEditPricing(true)
    getPlanConfigDetail({ pricing_plan_config_uid: e.planUid })
  }

  const onDelete = (e: any) => {
    Swal.fire({
      title: "Delete Plan Config",
      text: "Are you sure want to plan config?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2e4054",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
      didOpen: () => Swal.getConfirmButton()?.focus()
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlanConfig({ pricing_plan_config_uid: e.planUid })
      }
    });
  };

  const openModal = () => {
    setOpen(true)
    setIsEditPricing(false)
    resetPricing({
      
    })
  }

  const closeModal = () => {
    setOpen(false)
    resetPricing({
      
    })
  }

  const checkUid = () => {
    return (
      uid != undefined
      && uid != null
      && uid != ""
    )
  }

  //#endregion

  //#region UI VIEW

  const title = () => {
    var title = "";
    if(mode == FieldMode.create) {
      title = "Create Pricing Config";
    } else if(mode == FieldMode.edit) {
      title = "Update Pricing detail";
    } else if(mode == FieldMode.detail) {
      title = "Detail Pricing Config";
    }
    return <Typography variant="h3" fontFamily="Open Sans" mb={3}>
      {title}
    </Typography>
  }

  const buttons = () => {

    var saveBtn;
    if(mode == FieldMode.create || mode == FieldMode.edit) {
      saveBtn = <Button variant="contained" type="submit">
        {
          checkUid()
            ? ( mode == FieldMode.create ? "Finish" : "Save" )
            : "Next"
        }
      </Button>
    } else {
      saveBtn = null
    }

    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/pricing-config")}>
            Back
        </Button>
        {saveBtn}
      </Stack>
    )
  }
  
  const listPricing = () => {

    const itemPricing = (item: any) => {
      return (
        <Box sx={{
          backgroundColor: '#fff',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#4974a5'
        }}>
          {/* First vertical container */}
          <Box sx={{
              backgroundColor: '#DAE3ED',
              padding: '8px 0px 24px 0px',
              borderBottom: '1px solid #4974a5'
             }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
              <IconButton aria-label="edit" onClick={() => onEdit(item)} disabled={mode==FieldMode.detail}>
                <Edit/>
              </IconButton>
              <IconButton aria-label="delete" onClick={() => onDelete(item)} disabled={mode==FieldMode.detail}>
                <Delete/>
              </IconButton>
            </Box>
            <Typography variant="h6" align="center" fontWeight='bold' gutterBottom
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                paddingRight: '8px',
                paddingLeft: '8px'
              }}
            >{item.planName}</Typography>
            <Typography variant="body2" align="center"
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                paddingRight: '8px',
                paddingLeft: '8px',
                minHeight: '2em',
                lineHeight: '1em',
              }}
            >{item.planDescription}</Typography>
          </Box>
          {/* Second vertical container */}
          <Box sx={{ padding: '16px 16px 32px 16px' }}>
            <Typography variant="h6" fontWeight='bold' align="center" gutterBottom>Per-month</Typography>
            <Typography variant="subtitle1" align="center">{formatCurrency(item.price)}</Typography>
          </Box>
        </Box>
      )
    }

    return (
      <div style={{
          padding: '24px',
          borderColor: 'hsl(0, 0%, 80%)',
          width:'100%', marginTop: '16px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '6px',
        }}>
          {pricings.length === 0 ? (
            <div style={{ width: '100%', textAlign: 'center'}}>
              <Typography variant="body16semibold">No Pricing</Typography>
            </div>
          ) : (
            <Grid container spacing={3}>
              {pricings.map((item: any, index: number) => (
                <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  {itemPricing(item)}
                </Grid>
              ))}
            </Grid>
          )}
      </div>
    );

  };

  const partPricing = () => {
     return <>
     <Box sx={{ display: 'flex' }}>
      <Typography variant="h5" fontWeight="bold" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
        Pricing Information
      </Typography>
        { mode != FieldMode.detail ? <Button
          variant="contained"
          color="primary"
          onClick={() => openModal()}
          sx={{ height: '40px', width: '100px' }}>
            Add
        </Button> : null
        }
      </Box>
      {listPricing()}
      <Box sx={{height: '30px'}}/>
      </>
  }
  
  const contents = () => {
    return <>
    <Typography variant="h5" fontWeight="bold" fontFamily="Open Sans" mb={3}>
      Product Information
    </Typography>
    <SelectFormm
        name="productName"
        label="Product"
        defaultValue={0}
        options={optProduct}
        placeholder="none"
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
        disabled={mode==FieldMode.detail}
      />
    <Box sx={{height: '15px'}}/>
    <Typography variant="h5" fontWeight="bold" fontFamily="Open Sans" mb={3}>
      Payment Rules
    </Typography>
    <SelectFormm
        name="paymentType"
        label="Payment Type"
        defaultValue={0}
        options={optPayment}
        placeholder="none"
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
        disabled={mode==FieldMode.detail}
      />
    <Box sx={{height: '30px'}}/>
    { checkUid() ? partPricing() : null }
    </>
  }

  const addPricingModal = (
    <Modal open={open}>
      <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '30px',
          width: '50%'
        }}
        component="form"
        onSubmit={handlePricing(onAdd)}>

      <Box sx={{ display: 'flex' }} mb={1}>
        <Typography variant="h4" fontWeight="bold" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
        Add Pricing Config
        </Typography>
        <IconButton onClick={() => closeModal()}>
        <Close
          sx={{
            height:'20px',
            width:'20px',
            padding: '0px',
            alignSelf: 'center'
          }}
          />
        </IconButton>
      </Box>
      <Box sx={{height: '10px'}}/>

      <Typography variant="body16semibold" fontFamily="Open Sans" sx={{ flexGrow: 1 }}>
        Plan Information
      </Typography>
      <Box sx={{height: '20px'}}/>

      <Grid container spacing={4}>
        <Grid item xs={6} md={6}>
          <InputForm
              name="planName"
              label="Plan Name"
              control={controlPricing}
              errors={errorPricing}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Plan name.."
            />
            <InputForm
              name="price"
              label="Price"
              control={controlPricing}
              errors={errorPricing}
              required
              maxLength={100}
              isLoading={false}
              type="number"
              placeholder="Price.."
            />
            <InputForm
              name="planCode"
              label="Plan Code"
              control={controlPricing}
              errors={errorPricing}
              required
              maxLength={100}
              isLoading={false}
              placeholder="Plan code.."
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextAreaForm
              name="planDescription"
              label="Plan Description"
              defaultValue={""}
              control={controlPricing}
              errors={errorPricing}
              maxLength={300}
              required
              placeholder="Type plan description..."
            />
          </Grid>
      </Grid>

      <Box sx={{ display: 'flex' }}>
        <Button
          sx={{marginLeft: 'auto', marginRight: '8px' }}
          variant="contained"
          color="gray"
          onClick={() => closeModal()}>
            Cancel
        </Button>
        <Button variant="contained" onClick={handlePricing(async (e: any) => onAdd(e))}>
          Save
        </Button>
      </Box>

     </Box>
    </Modal>
  )

  //#endregion

  //#region RETURN

  return (
    <DetailDataSkeleton
      isLoading={isLoadingProduct || isLoadingPricingSchema || isLoadingPlanConfigSchema}
      isLoadingEvent={createLoading || updateLoading || createPlanConfigLoading || updatePlanConfigLoading || deletePlanConfigLoading}
      inputCount={6}
      titleWidth={'350px'}
    >
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      {title()}
      {contents()}
      {buttons()}
      {addPricingModal}
    </Box>
    </DetailDataSkeleton>
  );

  //#endregion

};

export default PricingConfigField;
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { makeStyles, styled, useTheme } from "@mui/material/styles";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useNavigate } from "react-router-dom";
import { arrSidebar } from "../../../constants/SidebarRouteName";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from 'react';
import korlantasLogo from "../../../assets/logo/korlantas-logo.png";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ISidebar } from "../../../interfaces/ISidebar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from 'react';

const Sidebar = ({ open, setOpen }: IOpenMainProps) => {

    //#region INIT

    const drawerWidth = 320;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [selected, setSelected] = useState(-1);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openChild, setOpenChild] = useState(false);
    const [selectedChild, setSelectedChild] = useState(-1);
    const [popoverOpen, setPopoverOpen] = useState(true);

    const openedMixin = () => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    });

    const closedMixin = () => ({
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    });

    //#endregion

    //#region HANDLE

    const handleDrawerClose = () => {
      setOpen(false);
    };
    
    const handleSelectedChild = (index: number) => {
      setSelectedChild(index);
      setPopoverOpen(true)
      setAnchorEl(null)
    };

    const handleSelectedClick = (path: any, index: number, child: ISidebar[]) => {
      if(child.length > 0) {
        if(open) {
          setSelected(selected === index ? -1 : index);
          setOpenChild((prev) => !prev);
        } else {
          setSelected(selected === index ? selected : index);
        }
      } else {
        setSelected(selected === index ? selected : index);
        if(path.length) {
          navigate(path);
          setSelectedChild(-1)
        } else {
          setSelectedChild(-1)
        }
      }
    };

    const handleNavigate = (event: any)=> {
      setAnchorEl(event.currentTarget);
    }

    const openPop = Boolean(anchorEl)
    const handleClose = () => {
      setAnchorEl(null);
    };

  //#endregion

    //#region RENDER
  
    const renderChildren = (children?: ISidebar[]) => {
      if (!children) return null;
      return children
      // .filter(item => accessSidebar.includes(item.key))
      .map(
        ({ key, label, path, icon: ComponentIcon, child: subChildren }, index) => {
          return (
            <ListItem
              key={key}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(path)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "initial",
                  px: 4,
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
                selected={selectedChild === index}
                onClick={() => handleSelectedChild(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: "center",
                    color:
                      selectedChild === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                  }}
                >
                  {ComponentIcon ? <ComponentIcon /> : null}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          );
        }
      );
    };
    
    const renderChildrenPopover = (children?: ISidebar[]) => {
      if (!children) return null;
      return children
      // .filter(item => accessSidebar.includes(item.key))
      .map(
        ({ key, label, path, icon: ComponentIcon, child: subChildren }, index) => {
          return (
              <ListItem
                key={key}
                disablePadding
                sx={{ display: "block", bgcolor: theme.palette.primary.main }}
                onClick={() => navigate(path)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 4,
                    color:
                      selectedChild === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                  }}
                  selected={selectedChild === index}
                  onClick={() => handleSelectedChild(index)}
                >
                  { ComponentIcon &&
                    <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                      color:
                        selectedChild === index
                          ? theme.palette.white.main
                          : theme.palette.white.darker,
                    }}
                  >
                  {ComponentIcon ? <ComponentIcon /> : null}
                  </ListItemIcon>
                  }
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            // </Popover>
          );
        }
      );
    };
  
    //#endregion

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: `${theme.palette.primary.main}`,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(),
          '& .MuiDrawer-paper': openedMixin(),
        }),
        ...(!open && {
          ...closedMixin(),
          '& .MuiDrawer-paper': closedMixin(),
        }),
      }}
      variant="permanent"
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.white.darker }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        )}
      </Box>
      <List>
        {
        arrSidebar
        // .filter(item => accessSidebar.includes(item.key))
        .map(
          ({ key, label, path, icon: ComponentIcon, child }: ISidebar, index) => (
            <React.Fragment key={key}>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={(event: any) => {
                    if(child != null) {
                      if(child.length > 0) {
                        handleNavigate(event)
                      }
                    }
                  }
                }
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color:
                      selected === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                  }}
                  onClick={() => handleSelectedClick(path, index, child!)}
                  selected={selected === index}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        selected === index
                          ? theme.palette.white.main
                          : theme.palette.white.darker,
                    }}
                  >
                  {ComponentIcon ? <ComponentIcon /> : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: theme.palette.white.darker,
                    }}
                  />
                  {
                    open &&
                    child!.length > 0 &&
                    (openChild ? <ExpandMoreIcon /> : <ChevronRightIcon />)
                  }
                </ListItemButton>
              </ListItem>
              {/* Render children */}

              {selected === index && open && renderChildren(child)}
              {selected === index && !open && ( 
                <Popover
                id="popover"
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                {renderChildrenPopover(child)}
                </Popover>
                )}
            </React.Fragment>
          )
        )}
      </List>
    </MuiDrawer>
  );

};

export default Sidebar;

import * as yup from "yup";
import { validationConfirmNewPasswordL, validationEmailField, validationNewPassword, validationNotEmpty, } from "./defaultValidations";

export default yup.object({
  firstName: validationNotEmpty("First Name"),
  email: validationEmailField("Email"),
  lastName: validationNotEmpty("Last Name"),
  mobile: validationNotEmpty("Mobile"),
  status: validationNotEmpty("Status"),
  roleGroup: validationNotEmpty("Role Group"),
});

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import PricingConfigSearch from "../../components/molecules/pricingConfig/PricingConfigSearch";
import PricingConfigTable from "../../components/molecules/pricingConfig/PricingConfigTable";
import { useGetAllPricingSchemaQuery } from "../../store/feature/service/pricingConfigApiSlice";

const PricingConfig = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAllPricingSchemaQuery(params);

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Pricing Config
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        </Box>
        <PricingConfigSearch params={params} setParams={setParams} />
        <PricingConfigTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default PricingConfig;

import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeletePricingSchemaMutation } from "../../../store/feature/service/pricingConfigApiSlice";
import { useEffect } from "react";

const PricingConfigTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    deletePricingSchema,
    {
      data: deletePayload,
      isLoading : deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      error: deleteErrorPayload
    }
  ] = useDeletePricingSchemaMutation();

  useEffect(() => {
    if (deleteSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Delete Pricing Schema",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Pricing Schema",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      })
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if(deleteErrorPayload) {
      console.log(deleteErrorPayload)
      if ('data' in deleteErrorPayload) {
        const dataError = 'error' in deleteErrorPayload ? deleteErrorPayload.error : JSON.stringify(deleteErrorPayload.data)
        const dataErrorJson =  JSON.parse(dataError)
        Swal.fire({
          icon: "error",
          title: "Error Delete Pricing Schema",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: dataErrorJson.error_details.detail,
          didOpen: () => Swal.getConfirmButton()?.focus(),
        })
      } 
    }
  }, [deleteErrorPayload]);

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    
    const onDetail = (e: any) => {
      navigate(`/pricing-config/detail/${params.id}`)
    };
    
    const onEdit = (e: any) => {
      navigate(`/pricing-config/edit/${params.id}`);
    };
    
    const onDelete = (e: any) => {
      Swal.fire({
        title: "Delete Pricing Schema",
        text: "Are you sure want to delete pricing?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2e4054",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus()
      }).then((result) => {
        if (result.isConfirmed) {
          deletePricingSchema({ pricing_schema_uid: params.id })
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
          <Button variant="contained" onClick={onEdit}>
            Edit
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "lastUpdate",
      headerName: "Last Update",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const tempData: any = data?.data?.data !== undefined ? data?.data?.data : [];

  // REGION: INIT ROWS
  const rows = tempData.map((row: any, index: number) => ({
    id: row.prc_schema_uid,
    productName: row.prd_name,
    lastUpdate: row.prc_schema_updated_at,
    status: row.prc_schema_status,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.data?.total_records}
      />
    </>
  );
};

export default PricingConfigTable;

export const optBusinessField = [
    {
      label: 'Pertanian, Kehutanan, dan Perikanan',
      value: 'Pertanian, Kehutanan, dan Perikanan'
    },
    {
      label: 'Pertambangan dan Penggalian',
      value: 'Pertambangan dan Penggalian'
    },
    {
      label: 'Industri Pengolahan',
      value: 'Industri Pengolahan'
    },
    {
      label: 'Pengadaan Listrik, Gas, Uap/Air Panas dan Udara Dingin',
      value: 'Pengadaan Listrik, Gas, Uap/Air Panas dan Udara Dingin'
    },
    {
      label: 'Treatment Air, Treatment Air Limbah, Treatment dan Pemulihan Material Sampah, dan Aktivitas Remediasi',
      value: 'Treatment Air, Treatment Air Limbah, Treatment dan Pemulihan Material Sampah, dan Aktivitas Remediasi'
    },
    {
      label: 'Kontruksi',
      value: 'Kontruksi'
    },
    {
      label: 'Perdagangan Besar Dan Eceran, Reparasi dan Perawatan Mobil dan Sepeda Motor',
      value: 'Perdagangan Besar Dan Eceran, Reparasi dan Perawatan Mobil dan Sepeda Motor'
    },
    {
      label: 'Pengangkutan dan Pergudangan',
      value: 'Pengangkutan dan Pergudangan'
    },
    {
      label: 'Penyediaan Akomodasi Dan Penyediaan Makan Minum',
      value: 'Penyediaan Akomodasi Dan Penyediaan Makan Minum'
    },
    {
      label: 'Informasi Dan Komunikasi',
      value: 'Informasi Dan Komunikasi'
    },
    {
      label: 'Aktivitas Keuangan dan Asuransi',
      value: 'Aktivitas Keuangan dan Asuransi'
    },
    {
      label: 'Real Estat',
      value: 'Real Estat'
    },
    {
      label: 'Aktivitas Profesional, Ilmiah Dan Teknis',
      value: 'Aktivitas Profesional, Ilmiah Dan Teknis'
    },
    {
      label: 'Aktivitas Penyewaan dan Sewa Guna Usaha Tanp Hak Opsi, Ketenagakerjaan, Agen Perjalanan dan Penunjang Usaha Lainnya',
      value: 'Aktivitas Penyewaan dan Sewa Guna Usaha Tanp Hak Opsi, Ketenagakerjaan, Agen Perjalanan dan Penunjang Usaha Lainnya'
    },
    {
      label: 'Administrasi Pemerintah, Pertahanan Dan Jaminan Sosial Wajib',
      value: 'Administrasi Pemerintah, Pertahanan Dan Jaminan Sosial Wajib'
    },
    {
      label: 'Pendidikan',
      value: 'Pendidikan'
    },
    {
      label: 'Aktivitas Kesehatan Manusia Dan Aktivitas Sosial',
      value: 'Aktivitas Kesehatan Manusia Dan Aktivitas Sosial'
    },
    {
      label: 'Kesenian, Hiburan Dan Rekreasi',
      value: 'Kesenian, Hiburan Dan Rekreasi'
    },
    {
      label: 'Aktivitas Jasa Lainnya',
      value: 'Aktivitas Jasa Lainnya'
    },
    {
      label: 'Aktivitas Rumah Tangga Sebagai Pemberi Kerja; Aktivitas Yang Menghasilkan Barang Dan Jasa Oleh Rumah Tangga yang Digunakan untuk memenuhi Kebutuhan Sendiri',
      value: 'Aktivitas Rumah Tangga Sebagai Pemberi Kerja; Aktivitas Yang Menghasilkan Barang Dan Jasa Oleh Rumah Tangga yang Digunakan untuk memenuhi Kebutuhan Sendiri'
    },
    {
      label: 'Aktivitas Badan Internasional Dan Badan Ekstra Internasional Lainnya',
      value: 'Aktivitas Badan Internasional Dan Badan Ekstra Internasional Lainnya'
    },
  ]
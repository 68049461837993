import * as yup from "yup";
import {
  validationNotEmpty,
} from "./defaultValidations";

export default yup.object({
  companyName: validationNotEmpty("Company Name"),
  productName: validationNotEmpty("Product Name"),
  // priceBaseOnPlan: validationNotEmpty("Price Base On Plan"),
  othersFee: validationNotEmpty("Others Fee"),
  dueDate: validationNotEmpty("Due Date"),
  note: validationNotEmpty("Note"),
});

import IRoutes from "../interfaces/IRoutes";
import CompanyList from "../pages/companyList/CompanyList";
import CompanyListField from "../pages/companyList/CompanyListField";

const CompanyListRoute: Array<IRoutes> = [
    {
        element: <CompanyList />,
        path: "/company-list",
    },
    {
        element: <CompanyListField />,
        path: "/company-list/create",
    },
    {
        element: <CompanyListField />,
        path: "/company-list/edit/:id",
    },
    {
        element: <CompanyListField />,
        path: "/company-list/detail/:id",
    }
];

export { CompanyListRoute };

import { apiSlice } from "../api/apiSlice";

export const productListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // PRODUCT LIST
    getAllProductList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/product/get-all`,
          params: params,
        };
      },
      providesTags: ["GetAllProductList"],
    }),
    getProductById: builder.mutation<any, any>({
      query: ({ id }) => {
        return {
          url: `/product/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetProductById"],
    }),
    getProductNameUid: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/product/name-and-uid`,
          params: params,
        };
      },
      providesTags: ["GetProductNameUid"],
    }),
    getAllCategories: builder.query<any, any>({
      query: () => {
        return {
          url: `/product-category/get-all`,
        };
      },
      providesTags: ["GetAllCategories"],
    }),
    createProductList: builder.mutation<any, any>({
      query: (body) => ({
        url: "/product/create",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreateProductList", "GetAllProductList"],
    }),
    updateProductList: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `/product/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateProductList', 'GetAllProductList']
    }),
    deleteProductList: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/product/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteProductList", "GetAllProductList"],
    }),


    // DASHBOARD LINK
    getAllDashbordLink: builder.mutation<any, any>({
      query: ({ product_uid }) => {
        return {
          url: `/product/dashboard_link/${product_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetAllDashboardLink"],
    }),
    getDashbordLinkById: builder.mutation<any, any>({
      query: ({ product_dashboard_link_uid }) => {
        return {
          url: `/product/dashboard_link/detail/${product_dashboard_link_uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetDashboardLinkById"],
    }),
    createDashboardLink: builder.mutation<any, any>({
      query: (body) => ({
        url: "/product/dashboard_link",
        method: "POST",
        body
      }),
      invalidatesTags: ["CreateDashboardLink", "GetAllDashboardLink"],
    }),
    updateDashboardLink: builder.mutation<any, any>({
      query: ({ body, product_dashboard_link_uid }) => ({
        url: `/product/dashboard_link/${product_dashboard_link_uid}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ['UpdateDashboardLink', 'GetAllDashboardLink']
    }),
    deleteDashboardLink: builder.mutation<any, any>({
      query: ({ product_dashboard_link_uid }) => ({
        url: `/product/dashboard_link/${product_dashboard_link_uid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeleteDashboardLink", "GetAllDashboardLink"],
    }),

  }),
});

export const {
  useGetAllProductListQuery,
  useGetProductByIdMutation,
  useGetAllCategoriesQuery,
  useGetProductNameUidQuery,
  useCreateProductListMutation,
  useUpdateProductListMutation,
  useDeleteProductListMutation,
  useGetAllDashbordLinkMutation,
  useGetDashbordLinkByIdMutation,
  useCreateDashboardLinkMutation,
  useUpdateDashboardLinkMutation,
  useDeleteDashboardLinkMutation
} = productListApiSlice;

import { Grid } from "@mui/material";
import React from "react";
import { FieldMode } from "../../constants/enum/FieldMode";
import { InputForm } from "../../components/molecules/forms";

const BillingDetail = ({ mode, errors, control }: any) => {
  return (
    <>
      {mode === FieldMode.detail && (
        <>
        <Grid item xs={6} md={6}>
          <InputForm
            name="dateIssued"
            label="Date Issued"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type Date Issued..."
            disabled
            required
          />
          {/* <InputForm
            name="billingPeriod"
            label="Billing Period"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type Billing Period..."
            disabled
            required
          /> */}
        </Grid>
        <Grid item xs={6} md={6}>
          <InputForm
            name="dueDateDetail"
            label="Due Date"
            errors={errors}
            control={control}
            maxLength={100}
            isLoading={false}
            placeholder="Type Date Issued..."
            disabled
            required
          />
        </Grid>
        </>
      )}
    </>
  );
};

export default BillingDetail;

import { apiSlice } from "../api/apiSlice";

export const companyListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // PRODUCT LIST
    getAllCompanyList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/company`,
          params: params,
        };
      },
      providesTags: ["GetAllCompanyList"],
    }),
    getAllCompanyListDropdown: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/company/list`,
          params: params,
        };
      },
      providesTags: ["GetAllCompanyList"],
    }),
    getCompanySubcriptionCmpId: builder.mutation<any, any>({
      query: ({ id }) => {
        return {
          url: `/company/subscription/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetCompanySubcriptionCmpId"],
    }),
    getCompanyId: builder.mutation<any, any>({
      query: ({ id }) => {
        return {
          url: `/company/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetCompanyIdDetail"],
    }),
    getCompanySubsEmail: builder.mutation<any, any>({
      query: ({ email, cmpId }) => {
        return {
          url: `/company/subscribe/email-and-product?email=${email}&product_uid=${cmpId}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetCompanySubsEmail"],
    }),
    registerECDSAtoEntrust: builder.mutation<any, any>({
      query: (body) => ({
        url: "/vault",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RegisterECDSAtoEntrust"],
    }),
    registerWSOPtoEntrust: builder.mutation<any, any>({
      query: (body) => ({
        url: "/protectiondomain",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RegisterWSOPtoEntrust"],
    }),
    createCompanyList: builder.mutation<any, any>({
      query: (body) => ({
        url: "/company",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CreateCompany", "GetAllCompanyList"],
    }),
    updateCompanyList: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/company/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UpdateCompany", "GetAllCompanyList"],
    }),
    createCompanySubscrire: builder.mutation<any, any>({
      query: (body) => ({
        url: "/company/subscribe",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CreateSubscribeCompany", "GetAllCompanyList"],
    }),
    //   updateProductList: builder.mutation<any, any>({
    //     query: ({body, id}) => ({
    //       url: `/product/${id}`,
    //       method: "PUT",
    //       body
    //     }),
    //     invalidatesTags: ['UpdateProductList', 'GetAllProductList']
    //   }),
    updateCompanySubscrire: builder.mutation<any, any>({
      query: (body) => ({
        url: "/company/subscribe",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CreateSubscribeCompany", "GetAllCompanyList"],
    }),
    updateCompanyDocument: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/company/document/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UpdateCompanyDocument"],
    }),
    //   deleteProductList: builder.mutation<any, any>({
    //     query: ({ id }) => ({
    //       url: `/product/${id}`,
    //       method: "DELETE",
    //     }),
    //     invalidatesTags: ["DeleteProductList", "GetAllProductList"],
    //   }),
    // Region Customer API
    createCustomer: builder.mutation<any, any>({
      query: (body) => ({
        url: "/customer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CreateCustomer", "GetAllCompanyList"],
    }),
    //company/subscribe
  }),
});

export const {
  useGetAllCompanyListQuery,
  useGetCompanySubcriptionCmpIdMutation,
  useCreateCompanyListMutation,
  useUpdateCompanyListMutation,
  useGetCompanySubsEmailMutation,
  useCreateCustomerMutation,
  useCreateCompanySubscrireMutation,
  useUpdateCompanyDocumentMutation,
  useUpdateCompanySubscrireMutation,
  useGetCompanyIdMutation,
  useGetAllCompanyListDropdownQuery,
  useRegisterECDSAtoEntrustMutation,
  useRegisterWSOPtoEntrustMutation
} = companyListApiSlice;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clearStorage, getToken } from '../../../utils/storage';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API }/`,
    prepareHeaders: (headers, { endpoint, getState }) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (endpoint == "generateLicense") {
        const state: any = getState()
        const licenseString = state.license.licenseString
        headers.set("encrypted-string", licenseString)
      }
      return headers;
    },
  });

  const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);
    let resp: any = result?.error?.status
    if(resp === 401){
      clearStorage();
      window.location.href= '/login';
    }
    return result;
  };

  export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: [
      'submitLogin',
      'GetProfileDetail',
      'UpdateProfilePassword',
      'GetAllProductList',
      'GetProductById',
      'GetProductNameUid',
      'GetAllCategories',
      'CreateProductList',
      'UpdateProductList',
      'DeleteProductList',
      'GetAllDashboardLink',
      'GetDashboardLinkById',
      'CreateDashboardLink',
      'UpdateDashboardLink',
      'DeleteDashboardLink',
      'GetAllUserAdmin',
      'GetUserAdminById',
      'CreateUserAdmin',
      'UpdateUserAdmin',
      'UpdateUserAdminPassword',
      'DeleteUserAdmin',
      'GetAllRoles',
      'GetAllPricingSchema',
      'GetPricingSchemaById',
      'CreatePricingSchema',
      'UpdatePricingSchema',
      'DeletePricingSchema',
      'GetPricingSchemaByProductId',
      'GetPlanConfigById',
      'CreatePlanConfig',
      'UpdatePlanConfig',
      'DeletePlanConfig',
      'GetPlanConfigBySchemaId',
      'GetAllCompanyList',
      'CreateCompany',
      'CreateCustomer',
      'CreateSubscribeCompany',
      'GetCompanySubcriptionCmpId',
      'GetCompanyIdDetail',
      'UpdateCompany',
      'GetCompanySubsEmail',
      "UpdateCompanyDocument",
      "GetAllBilling",
      "CreateBilling",
      "GetBillingById",
      "UpdateBilling",
      "RegisterWSOPtoEntrust",
      "RegisterECDSAtoEntrust"
    ],
  });
  
  export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
  
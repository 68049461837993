import * as yup from "yup";
import { validationEmailField, validationNotEmpty } from "./defaultValidations";

export default yup.object({
  companyName: validationNotEmpty("Company Name"),
  mobile: validationNotEmpty("Company Phone Number"),
  businessField: validationNotEmpty("Business Field"),
  // vatNumber: validationNotEmpty("VAT Number"),
  companyStatus: validationNotEmpty("Company Status"),
  companyEmail: validationEmailField("Company Email"),
  companyAddress: validationNotEmpty("Company Adress"),
  // note: validationNotEmpty("Note"),
  picName: validationNotEmpty("PIC Name"),
  mobilePic: validationNotEmpty("PIC Phone Number"),
  picEmail: validationEmailField("PIC Email"),
  picRole: validationNotEmpty("PIC Role"),
});

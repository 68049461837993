import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import ProductListSearch from "../../components/molecules/productList/ProductListSearch";
import ProductListTable from "../../components/molecules/productList/ProductListTable";
import { useGetAllProductListQuery } from "../../store/feature/service/productListApiSlice";

const ProductList = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAllProductListQuery(params);

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Product List
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        </Box>
        <ProductListSearch params={params} setParams={setParams} />
        <ProductListTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default ProductList;

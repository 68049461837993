const formatNumberWithCommas = (number: any) => {
  // Convert the number to a string
  const numberString = number.toString();

  // Use regular expressions to add commas as thousand separators
  // The regex pattern (\d)(?=(\d{3})+$) matches every digit (\d)
  // that is followed by groups of three digits (\d{3}) until the end of the string ($)
  // and adds a comma between them
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export default formatNumberWithCommas;

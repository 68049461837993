import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  title: yup.string().required(),
});

const CompanyListSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");
  const [userActive, setUserActive] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
  } = useForm<{
    product: string;
    category: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      product: "",
      category: "",
      status: ""
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleProduct = (e: string) => { setProduct(e); };
  const handleCategorySelect = (e: any) => { setCategory(e); };
  const handleStatusSelect = (e: any) => { 
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
  };
  useDebouncedEffect(() => setSearchFilterDebounced(product), [product], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(category), [category], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(userActive), [userActive], 500); 

  useEffect(() => {
    setParams({
      ...params,
      company_name: product,
      company_uid: category,
      company_status: userActive, 
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="product"
          errors={errors}
          control={control}
          required
          placeholder="Company Name"
          maxLength={100}
          isLoading={false}
          onChange={handleProduct}
        />
        <InputForm
          name="category"
          errors={errors}
          control={control}
          required
          placeholder="Registration Number"
          maxLength={100}
          isLoading={false}
          onChange={handleCategorySelect}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/company-list/create")}
            sx={{ marginBottom: "0px", marginTop: "8px", height: '40px', width: '200px' }}
          >
            <AddIcon /> Create Company
          </Button>
      </Box>
    </Box>
  );
};

export default CompanyListSearch;

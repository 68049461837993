import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SelectForm from "../forms/selectFormm";

const schema = yup.object({
  title: yup.string().required(),
});

const BillingInvoiceSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [billingNumber, setBillingNumber] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [userActive, setUserActive] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
  } = useForm<{
    companyName: string;
    billingNumber: string;
    lastLogin: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: "",
      billingNumber: "",
      lastLogin: "",
      status: ""
    },
  });

  const optSelect = [
    {
      label: "OPEN",
      value: "open",
    },
    {
      label: "PAID",
      value: "paid",
    },
    {
      label: "CANCEL",
      value: "cancel",
    },
  ];

  const handleCompanyName = (e: string) => { setCompanyName(e); };
  const handleBillingNumber = (e: string) => { setBillingNumber(e)};
  const handleLastLogin = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setLastLogin(NewDate);
  };
  const handleStatusSelect = (e: any) => { 
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
  };

  useDebouncedEffect(() => setSearchFilterDebounced(companyName), [companyName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(billingNumber), [billingNumber], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(lastLogin), [lastLogin], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(userActive), [userActive], 500); 

  useEffect(() => {
    // cpn_bill_uid : billNumber
    // cpn_bill_status : billStatus
    // cpn_bill_created_start : startCreate
    // cpn_bill_created_end : endCreate
    setParams({
      ...params,
      cpn_bill_company_name: companyName,
      cpn_bill_uid: billingNumber,
      cpn_bill_created_start: lastLogin,
      cpn_bill_created_end: lastLogin,
      cpn_bill_status: userActive,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="companyName"
          errors={errors}
          control={control}
          required
          placeholder="Company Name"
          maxLength={100}
          isLoading={false}
          onChange={handleCompanyName}
        />
        <InputForm
          name="billingNumber"
          errors={errors}
          control={control}
          required
          placeholder="Billing Number"
          maxLength={100}
          isLoading={false}
          onChange={handleBillingNumber}
        />
        <DatePickerForm
          name="lastLogin"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Last Login"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLastLogin}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/billing-invoice/create")}
            sx={{ marginBottom: "0px", marginTop: "8px", height: '40px', width: '200px' }}
          >
            <AddIcon /> Create Billing Invoice
          </Button>
      </Box>
    </Box>
  );
};

export default BillingInvoiceSearch;

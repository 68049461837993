import * as yup from "yup";
import { validationNotEmpty, } from "./defaultValidations";

export default yup.object({
  productName: validationNotEmpty("Product Name"),
  productIcon: validationNotEmpty("Product Icon"),
  // productImageBanner: validationNotEmpty("Product Image Banner"),
  productStatus: validationNotEmpty("Product Status"),
  productCategory: validationNotEmpty("Product Category"),
  productDocumentationLink: validationNotEmpty("Product Documentation Link"),
  productDescription: validationNotEmpty("Product Description"),
});

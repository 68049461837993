import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                const { Email, Password } = credentials;
                const pasEnc = encrypt(Password);
                return ({
                    url: '/auth/login',
                    method: 'POST',
                    body: {
                        email: Email,
                        password: pasEnc,
                    },
                })
            },
            invalidatesTags:['submitLogin']
        }),
        getProfileDetail: builder.mutation<any, any>({
          query: () => ({
            url: `/user`,
            method: "GET"
          }),
          invalidatesTags: ['GetProfileDetail']
        }),
        updateProfilePassword: builder.mutation<any, any>({
          query: ({ oldPassword, newPassword }) => {
            const encryptedOldPassword = encrypt(oldPassword);
            const encryptedNewPassword = encrypt(newPassword);
            return {
              url: `/user/change-password`,
              method: "PUT",
              body: {
                old_password: encryptedOldPassword,
                update_password: encryptedNewPassword
              }
            }
          },
          invalidatesTags: ['UpdateProfilePassword']
        }),
    })
})

export const {
    useSubmitLoginMutation,
    useGetProfileDetailMutation,
    useUpdateProfilePasswordMutation
} = userApiSlice
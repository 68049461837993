import * as yup from "yup";
import { validationConfirmNewPasswordL, validationEmailField, validationNewPassword, validationNotEmpty, } from "./defaultValidations";

export default yup.object({
  productName: validationNotEmpty("Product Name"),
  planName: validationNotEmpty("Plan Name"),
  adminName: validationNotEmpty("Admin Name"),
  adminEmail: validationEmailField("Admin Email"),
  adminPhoneNumber: validationNotEmpty("Admin Phone Number"),
  password: validationNewPassword("Password"),
  confirmPassword: validationConfirmNewPasswordL("Confirm Password"),
});

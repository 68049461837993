import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import { useGetAllProductListQuery } from "../../store/feature/service/productListApiSlice";
import ProductSubscriptionSearch from "../../components/molecules/productSubscription/ProductSubscriptionSearch";
import ProductSubscriptionTable from "../../components/molecules/productSubscription/ProductSubscriptionTable";
import autentikaImg from "../../assets/logo/medium.png";

const ProductSubscription = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { data, isLoading } = useGetAllProductListQuery(params);

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={false}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Product Subscription
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", marginY: "20px" }}>
          <Box
            sx={{ display: "flex", gap: "20px", flex: 2, alignItems: "center" }}
          >
            <img
              src={autentikaImg} // Free image from Unsplash
              alt="circle"
              width="100"
              height="100"
              style={{ borderRadius: "50%", marginRight: "20px" }}
            />
            <Typography variant="body16semibold" sx={{ marginRight: "20px" }}>
              WSOP
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flex: 1 }}>
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center items horizontally
                gap: "5px", // Spacing between items
                maxWidth: "200px", // Limiting width for better layout
                textAlign: "center", // Center text horizontally within the box
              }}
            >
              <Typography variant="h4" sx={{ margin: 0 }}>
                4
              </Typography>
              <Typography variant="body2">
                Total Active
                <br />
                Company subscribe
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center items horizontally
                gap: "5px", // Spacing between items
                maxWidth: "200px", // Limiting width for better layout
                textAlign: "center", // Center text horizontally within the box
              }}
            >
              <Typography variant="h4" sx={{ margin: 0 }}>
                1
              </Typography>
              <Typography variant="body2">
                Total Active Plan
              </Typography>
            </Box>
          </Box>
        </Box>
        <ProductSubscriptionSearch params={params} setParams={setParams} />
        <ProductSubscriptionTable
          data={data !== undefined || null ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default ProductSubscription;

export const generateRandomString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const formatCurrency = (value: any) => {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      return `Rp ${numberValue.toLocaleString('id-ID')}`;
    } else {
      return value;
    }
  };

import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import ProductList from '../pages/productList/ProductList';
import ProductListField from '../pages/productList/ProductListField';
import PricingConfig from '../pages/pricingConfig/PricingConfig';
import PricingConfigField from '../pages/pricingConfig/PricingConfigField';
import UserAdmin from '../pages/userAdmin/UserAdmin';
import UserAdminField from '../pages/userAdmin/UserAdminField';
import UserAdminUpdatePassword from '../pages/userAdmin/UserAdminUpdatePassword';
import { CompanyListRoute } from './companyList';
import { BillingInvoiceRoute } from './billingInvoice';
import ProductSubscription from '../pages/product Subscription/ProductSubscription';
import ProductSubscriptionField from '../pages/product Subscription/ProductSubscriptionField';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/profile',
      },

      // PRODUCT LIST
      {
        element: <ProductList />,
        path: '/product-list',
      },
      {
        element: <ProductListField />,
        path: '/product-list/create',
      },
      {
        element: <ProductListField />,
        path: '/product-list/edit/:id',
      },
      {
        element: <ProductListField />,
        path: '/product-list/detail/:id',
      },


      // PRODUCT SUBSCRIPTION
      {
        element: <ProductSubscription />,
        path: '/product-subscriptions',
      },
      {
        element: <ProductSubscriptionField />,
        path: '/product-subscriptions/create',
      },
      {
        element: <ProductSubscriptionField />,
        path: '/product-subscriptions/edit/:id',
      },
      {
        element: <ProductSubscriptionField />,
        path: '/product-subscriptions/detail/:id',
      },

      // PRICING CONFIG
      {
        element: <PricingConfig />,
        path: '/pricing-config',
      },
      {
        element: <PricingConfigField />,
        path: '/pricing-config/create',
      },
      {
        element: <PricingConfigField />,
        path: '/pricing-config/edit/:id',
      },
      {
        element: <PricingConfigField />,
        path: '/pricing-config/detail/:id',
      },

      
      // USER ADMIN
      {
        element: <UserAdmin />,
        path: '/user-admin',
      },
      {
        element: <UserAdminField />,
        path: '/user-admin/create',
      },
      {
        element: <UserAdminField />,
        path: '/user-admin/edit/:id',
      },
      {
        element: <UserAdminField />,
        path: '/user-admin/detail/:id',
      },
      {
        element: <UserAdminUpdatePassword />,
        path: '/user-admin/update-password/:id',
      },
      ...CompanyListRoute,
      ...BillingInvoiceRoute

    ],
  },
];

export default routes;
